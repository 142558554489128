import { Database } from './../lib/database.types';
import { Injectable, signal } from '@angular/core';
import { SupabaseClient, createClient, User } from '@supabase/supabase-js';
import { BehaviorSubject, combineLatest, firstValueFrom, throwError, TimeoutError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';
import { Photo } from '@capacitor/camera';
import { isPlatform, RefresherCustomEvent, ToastController, ToastOptions } from '@ionic/angular';
import { Filesystem } from '@capacitor/filesystem';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { NgxSpinnerService } from 'ngx-spinner';
import { differenceInWeeks, toDate } from 'date-fns';
import { map, tap, take, catchError, timeout } from 'rxjs/operators';
import { NetworkService } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import {
  BracketWithMatches,
  BracketWithWrestlers,
  BracketWithWrestlersAndMatchNumbers,
  BracketWrestler,
  BracketWrestlerWithDetails,
  Division,
  DivisionUpdate,
  DivisionWithMatchSettings,
  EventAutogenSettings,
  EventBracket,
  EventBracketUpdate,
  EventDivision,
  EventDivisionFullNested,
  EventDivisionUpdate,
  EventMatchDurationsWithMatsView,
  EventWrestler,
  EventWrestlerWithTeam,
  EventWrestlerWithTeamAndDivisions,
  EventsAgainstPerWrestlerView,
  EventsForAgainstPerTeamView,
  EventsForPerWrestlerView,
  FavoriteWrestler,
  League,
  LeagueAdmins,
  LeagueAdminsFull,
  LeagueDivision,
  LeagueDivisionUpdate,
  LeagueEvent,
  LeagueEventWithLinks,
  LeagueWeightClass,
  LeagueWithAdmins,
  MatNotification,
  Match,
  MatchActivity,
  MatchActivityUpdate,
  MatchSettings,
  MatchSettingsUpdate,
  MatchUpdate,
  MatchWithEventAndWrestlers,
  MatchWithWrestlers,
  MatchWithWrestlersAndSettingsAndBracket,
  MatchWithWrestlersUpdate,
  Team,
  TeamPoints,
  TeamStatsViewWithWrestlerName,
  TeamWinsLossesView,
  TeamsJoinedEventTeams,
  TeamsWithEventWrestlers,
  WeightHistory,
  Wrestler,
  WrestlerEventMatches,
  WrestlerImport,
  WrestlerWithTeam,
} from '../lib/collection.types';

export const LEAGUES_TABLE = 'leagues';
export const LEAGUE_EVENTS_TABLE = 'league_events';
export const LEAGUE_WEIGHT_CLASSES_TABLE = 'league_weight_classes';
export const EVENT_TEAMS_TABLE = 'event_teams';
export const EVENT_WRESTLERS_TABLE = 'event_wrestlers';
export const EVENT_BRACKETS_TABLE = 'event_brackets';
export const MATCHES_TABLE = 'matches';
export const BOUT_EVENTS_TABLE = 'match_activities';
export const TEAMS_TABLE = 'teams';
export const WRESTLERS_TABLE = 'wrestlers';
export const BUCKET = 'images';
export const NOTIF_TABLE = 'mat_notifications';
export const EVENT_AUTOGEN_SETTINGS_TABLE = 'event_autogen_settings';
export const MATCH_SETTINGS_TABLE = 'match_settings';
export const LEAGUE_DIVISIONS_TABLE = 'league_divisions';
export const DIVISIONS_TABLE = 'divisions';
export const EVENT_DIVISIONS_TABLE = 'event_divisions';
type UndefinedToNull<T> = {
  [K in keyof T]: T[K] extends undefined ? null : T[K];
};

export function undefinedToNull<T>(optionalObject: Partial<T>): T {
  const newObject: any = {};

  for (const key in optionalObject) {
    newObject[key] = optionalObject[key] === undefined ? null : optionalObject[key];
  }

  return newObject as T;
}

export interface WrestlerFilter {
  age_min: number | null;
  age_max: number | null;
  weight_min: number | null;
  weight_max: number | null;
  count?: number | null;
}

export interface WrestlerRankUpdate {
  wrestler_id: number;
  new_rank: number;
}

export interface SearchUser {
  id: string;
  email: string;
}
export interface PopoverItems {
  name: string;
  value: string;
  icon?: string;
  color: string;
  badge?: number;
  subItem?: PopoverItems[];
  func?: () => void;
}
interface GeneratorWrestler {
  id: number;
  name: string;
  weight: number;
  age: number;
  gender: string;
  grade: number;
  rank: number;
  composite: number;
  team_id: number;
  wins: number;
  losses: number;
  wrestler_id: number;
}

export interface MatchupGeneratorOptions {
  ageGap: number;
  rankGap: number;
  gradeGap: number;
  sameTeamMatchups: 'allow' | 'disallow' | 'discourage';
  weightThresholdPercent: number;
  bracketStyle: '2match' | 'medals' | 'medalsRR';
  mixedGender: boolean;
}
interface GeneratorResult {
  numWrestlers: number;
  numCombinations: number;
  averageBracketScore: number;
  numBrackets: number;
  brackets: Array<Array<GeneratorWrestler>>;
}

export enum RoundType {
  Consi = 1,
  Champ = 2,
}
export enum BracketTypes {
  // set explicit values since these will be stored in the database
  Empty = 0,
  TwoMatch1Man = 1,
  TwoMatch2Man = 2,
  TwoMatch3Man = 3,
  TwoMatch4Man = 4,
  TwoMatch5Man = 5,
  // TwoMatch6Man = 6,
  Place2MP2 = 100,
  Place4MP4 = 101,
  Place8MWB3P6 = 102,
  Place16MWB3P6 = 103,
  // Place2MBestOf3 = 104,
  Place3MRR = 105,
  Place4MRR = 106,
  // Place5MRR = 107,
  // Place6MRR = 108,
  Dual = 200,
}

export const BracketTypeStrings: { [key: number]: string } = {
  [BracketTypes.Empty]: 'Empty',
  [BracketTypes.TwoMatch1Man]: '1 Man - BYE',
  [BracketTypes.TwoMatch2Man]: '2 Man Wrestle Twice',
  [BracketTypes.TwoMatch3Man]: '3 Man Round Robin',
  [BracketTypes.TwoMatch4Man]: '4 Man Preset',
  [BracketTypes.TwoMatch5Man]: '5 Man Partial Round Robin',
  // [BracketTypes.TwoMatch6Man]: '6 Man Partial Round Robin',
  [BracketTypes.Place2MP2]: '2 Man Place 2',
  [BracketTypes.Place4MP4]: '4 Man Place 4',
  [BracketTypes.Place8MWB3P6]: '8 Man WB-3rd Place 6	',
  [BracketTypes.Place16MWB3P6]: '16 Man WB-3rd Place 6',
  // [BracketTypes.Place2MBestOf3]: '2 Man Best of 3',
  [BracketTypes.Place3MRR]: '3 Man Round Robin Place 3',
  [BracketTypes.Place4MRR]: '4 Man Round Robin Place 4',
  // [BracketTypes.Place5MRR]: '5 Man Round Robin',
  // [BracketTypes.Place6MRR]: '6 Man Round Robin',
  [BracketTypes.Dual]: 'Dual',
};

// curl --location --request OPTIONS 'https://c2sxdni4inlamdmp47zq6htlai0nkvzw.lambda-url.us-east-1.on.aws/' \
// --header 'Origin: http://example.com' \
// --header 'Access-Control-Request-Method: DELETE' -v
// curl -d '{"demo":"true", "key2":"value2"}' -H "Content-Type: application/json" -X POST https://c2sxdni4inlamdmp47zq6htlai0nkvzw.lambda-url.us-east-1.on.aws/
const MATCHUP_GEN_URL = 'https://c2sxdni4inlamdmp47zq6htlai0nkvzw.lambda-url.us-east-1.on.aws/';

const NOTIFY_URL = 'https://pseaokq5ghpws6zwnzt4fcnrke0ozhwn.lambda-url.us-east-1.on.aws/';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  private supabase: SupabaseClient;
  private syncInProgress = false;

  get client(): SupabaseClient {
    return this.supabase;
  }
  constructor(
    private router: Router,
    private storage: Storage,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
    private toastController: ToastController,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
  ) {
    this.supabase = createClient<Database>(environment.supabaseUrl, environment.supabaseKey);

    // Subscribe to network status changes to trigger sync when back online
    this.networkService.onlineStatus$.subscribe((online) => {
      if (online) {
        this.processPendingSync();
      }
    });
  }

  viewMode$ = new BehaviorSubject<'default' | 'coach'>('default');

  getUuid4() {
    return uuidv4();
  }

  // TODO: fixme
  isUserSnapdownAdmin() {
    const user = this._currentUser.value;
    return user && user.email === 'robert@erafx.com';
  }

  public _currentUser: BehaviorSubject<boolean | User | any> = new BehaviorSubject(null);

  currentUser = this._currentUser.asObservable();

  age(dob: string) {
    const date = new Date(dob);

    // const date = parse(value, "yyyy-MM-dd", new Date());
    const age = differenceInWeeks(new Date(), date) / 52.0;
    return age;
  }

  defaultFilters: WrestlerFilter = {
    age_min: null,
    age_max: null,
    weight_min: null,
    weight_max: null,
    count: 0,
  };

  // selectedMatSignal = signal(0);
  selectedMatSubject$ = new BehaviorSubject<number>(0);
  selectedTeamSubject$ = new BehaviorSubject<number>(0);

  matsToShow$ = new BehaviorSubject<number[]>([]);
  teamsToShow$ = new BehaviorSubject<number[]>([]);

  resetTeamsToShow() {
    this.teamsToShow$.next([]);
  }

  resetMatsToShow() {
    this.matsToShow$.next([]);
  }

  adminBracketSelectSort$ = new BehaviorSubject('first_name');
  teamSort$ = new BehaviorSubject('first_name');

  updateFilters(filters: WrestlerFilter) {
    this._filterSubject$.next(filters);
  }

  hasValue(val: any) {
    return val != null && val !== '';
  }

  _filterSubject$: BehaviorSubject<WrestlerFilter> = new BehaviorSubject(this.defaultFilters);
  filterSubjectWithCount$ = this._filterSubject$.pipe(
    map((filters) => ({
      ...filters,
      count: (this.hasValue(filters.age_min) ? 1 : 0) + (this.hasValue(filters.age_max) ? 1 : 0) + (this.hasValue(filters.weight_min) ? 1 : 0) + (this.hasValue(filters.weight_max) ? 1 : 0),
    })),
  );

  async getEventTeamScores(eventId: number) {
    const res = await this.supabase.rpc('get_team_points', {
      league_event_id_param: eventId,
    });

    if (res.error) {
      return [] as TeamPoints;
    }
    return res.data as TeamPoints;
  }

  async addFavoriteWrestler(wrestler: WrestlerWithTeam) {
    const obj = {
      wrestler_id: wrestler.id,
      user_id: this._currentUser.value.id,
    };
    const { data, error } = await this.supabase.from('user_favorite_wrestlers').upsert(obj, { onConflict: 'user_id,wrestler_id' });
    if (error) {
      console.error('Error adding favorite wrestler:', error);
      this.showToast('Failed to add favorite wrestler.', { duration: 3000 });
      return false;
    }
    return true;
  }

  async removeFavoriteWrestler(wrestler: WrestlerWithTeam) {
    const { data, error } = await this.supabase.from('user_favorite_wrestlers').delete().eq('wrestler_id', wrestler.id).eq('user_id', this._currentUser.value.id);
    if (error) {
      console.error('Error removing favorite wrestler:', error);
      this.showToast('Failed to remove favorite wrestler.', { duration: 3000 });
      return false;
    }
    return true;
  }

  async getFavorites(): Promise<WrestlerWithTeam[]> {
    const { data, error } = await this.supabase
      .from('user_favorite_wrestlers')
      .select(
        `
        wrestler_id,
        wrestler: wrestlers!inner (
          *,
          team:teams (
            id,
            name
          )
        )
      `,
      )
      .eq('user_id', this._currentUser.value.id)
      .returns<FavoriteWrestler[]>();

    if (error) {
      console.log(`Error fetching favorites: ${error.message}`);
    }

    if (data) {
      // Transform the nested response into WrestlerWithTeam array
      return data.map((favorite) => ({
        ...favorite.wrestler,
      }));
    } else {
      return [];
    }
  }

  async getMatchesInLastNDays(leagueId: number, days: number) {
    //if days is not set, use 10
    days = days || 10;
    const res = await this.supabase.rpc('get_completed_matches_weighted', {
      league_id_in: leagueId,
      num_days: days,
    });
    // console.log(res);
    if (res.error) {
      return [];
    }

    return res.data.map((match: { winner: number; loser: number; days_ago: number }) => [match.winner, match.loser, match.days_ago]);
  }

  // TODO fixme
  bracketsFullWithMatches$ = new BehaviorSubject<BracketWithMatches[]>([]);

  // TODO fixme
  // This pulls in the event bracket, the bracket wrestlers, and the event_wrestlers inside the bracket_wrestlers
  brackets$ = new BehaviorSubject<BracketWithWrestlers[]>([]);
  async generateMatchups(
    league_id: number,
    eventDetails: LeagueEvent | null,
    eventId: number,
    wrestlersToSend: EventWrestlerWithTeam[],
    options: EventAutogenSettings,
    division_id: number,
    keep_manually_added: boolean,
  ) {
    try {
      await this.spinner.show('matchups');

      const wrestlers = wrestlersToSend
        .filter((w) => w.new_weight)
        .map(
          (w) =>
            ({
              id: w.id,
              name: w.first_name + ' ' + w.last_name,
              weight: w.new_weight,
              age: this.age(w.dob),
              gender: w.gender,
              grade: w.grade,
              rank: w.rank,
              wins: w.wins ? w.wins : 0,
              losses: w.losses ? w.losses : 0,
              composite: w.composite,
              team_id: w.team.sibling ? w.team.sibling : w.team.id,
              wrestler_id: w.wrestler_id,
            }) as GeneratorWrestler,
        );

      const demo = false;
      const previousMatches = await this.getMatchesInLastNDays(league_id, 30);

      const optionsToSend = {
        ageGap: options.age_gap,
        rankGap: options.skill_gap,
        gradeGap: options.grade_gap,
        sameTeamMatchups: options.same_team,
        weightThresholdPercent: options.weight_diff,
        bracketStyle: options.bracket_types,
        mixedGender: options.mixed_gender,
      };

      const result = await firstValueFrom(
        this.httpClient
          .post<GeneratorResult>(MATCHUP_GEN_URL, {
            demo,
            wrestlers,
            options: optionsToSend,
            previousMatches,
          })
          .pipe(
            timeout(8 * 60 * 1000), // Add timeout of 30 seconds
            catchError((error) => {
              if (error instanceof TimeoutError) {
                throw new Error('Request timed out. Please check your connection.');
              }
              if (error.status === 0) {
                throw new Error('Network error. Please check your internet connection.');
              }
              throw new Error(`Failed to generate matchups: ${error.message}`);
            }),
          ),
      );

      await this.setRawBracketData(eventId, result.brackets, options, division_id, keep_manually_added);
    } catch (error: unknown) {
      console.error('Error in generateMatchups:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to generate matchups.';
      this.showToast(errorMessage, { duration: 3000 });
      throw error;
    } finally {
      this.spinner.hide('matchups');
    }
  }

  // array of tokens, title, data (string with newlines)
  async sendPushNotifications(tokens: string[], title: string, data: string) {
    // tokens = ['dGEQR5Qu907vuvu2xNCn44:APA91bEpBy_iS6VA0cg4rh3hFfzE1L8BRKsU4j3OBlcIkE3W-JDhPfASJgh5U0qKjhaHjeu_xkceTZfGx-2aj9oKI9OpvKioaygS0jtJLFFO0oKSA0B-p0oe2qojCKG_zoSoVlFY2-4j']
    let res = this.httpClient
      .post(NOTIFY_URL, { tokens, title, data })
      .pipe(
        tap(
          (res) => {},
          async (err) => {
            console.log('got error?');
            this.showToast('Failed to send notification.', { duration: 3000 });
          },
        ),
      )
      .subscribe((res) => {
        console.log('notifcations ok?');
      });
  }

  async setRawBracketData(eventId: number, rawBrackets: Array<Array<GeneratorWrestler>>, options: EventAutogenSettings, division_id: number, keep_manually_added: boolean) {
    const brackets: BracketWithWrestlers[] = rawBrackets.map((b, index) => {
      const rawBracket = b;
      const length = rawBracket.length;
      // console.log(rawBracket, length);

      return this.rawWrestlerBracketToFullObject(b, index, options.bracket_types || '2match', division_id);
    });

    await this.deleteBrackets(eventId, division_id, keep_manually_added);

    await this.saveBrackets(eventId, brackets);
    await this.loadBrackets(eventId);
  }
  // const lists = await this.supabase
  // .from(TEAMS_TABLE)
  // .select('*,' + EVENT_TEAMS_TABLE + '!inner(*)')
  // .eq(EVENT_TEAMS_TABLE + '.event_id', eventId)
  // .order('name');

  async getUserSeenBrackets(eventId: number) {
    const { data, error } = await this.supabase.from('user_bracket_marked').select('bracket_id').eq('event_id', eventId);

    if (error) {
      // throw error;
    }
    console.log(data);
    return data?.map((r) => r.bracket_id) || [];
  }

  async setUserSeenBracket(eventId: number, bracketId: string) {
    const res = await this.supabase
      .from('user_bracket_marked')
      .insert({
        event_id: eventId,
        user_id: this._currentUser.value.id,
        bracket_id: bracketId,
      })
      .select();
  }

  async removeUserSeenBracket(bracketId: string) {
    const res = await this.supabase.from('user_bracket_marked').delete().eq('bracket_id', bracketId);
  }

  async fetchBracketsByUuidNotCalledToMat(uuids: string[]) {
    let { data, error } = await this.supabase
      .from(EVENT_BRACKETS_TABLE)
      .select('*, wrestlers:bracket_wrestlers!inner(*, wrestler:event_wrestler_id(*, team:team_id(*)))')
      .eq('call_to_mat', false)
      .in('uuid', uuids)
      .order('position')
      .returns<BracketWithWrestlers[]>();
    // TODO

    // console.log('fetch brackets by uuid', uuids, data, error);

    return data || [];
  }

  // async fetchBracketsWithMatchesForEvent(eventId) {
  //   let { data, error } = await this.supabase
  //     .from(EVENT_BRACKETS_TABLE)
  //     .select(
  //       '*, wrestlers:bracket_wrestlers!inner(*, wrestler:event_wrestler_id(*, team:team_id(*))), ' +
  //         'matches!inner(match_number)'
  //     )
  //     // .eq('is_complete', false)
  //     .eq('event_id', eventId)
  //     .order('position');

  //   // console.log('fetch brackets called to mat', data, error);

  //   return data || [];
  // }

  async fetchBracketWithMatchesByUUID(bracket_uuid: string) {
    if (!bracket_uuid) return null;

    let { data, error } = await this.supabase
      .from(EVENT_BRACKETS_TABLE)
      .select(
        '*, ' +
          MATCHES_TABLE +
          '!inner(*,' +
          'wrestler1(id, first_name, last_name,  wrestler_id, team:team_id(id, name)), ' +
          'wrestler2(id, first_name, last_name, wrestler_id, team:team_id(id, name)) ' +
          ')',
      )
      // .eq('is_complete', false)
      .eq('uuid', bracket_uuid)
      .returns<BracketWithMatches[]>()
      .limit(1)
      .single();

    console.log('fetch brackets called to mat', data, error);

    if (data) {
      // sort by match number...
      data.matches.sort((a, b) => {
        if (a.match_number === null) return -1;
        if (b.match_number === null) return 1;
        return a.match_number - b.match_number;
      });
    }
    return data || null;
  }

  async fetchBracketsCalledToMat(eventId: number) {
    // TODO: add type for this
    // Type needs to be BracketWithWrestlersWithMatches
    let { data, error } = await this.supabase
      .from(EVENT_BRACKETS_TABLE)
      .select('*, wrestlers:bracket_wrestlers!inner(*, wrestler:event_wrestler_id(*, team:team_id(*))), ' + 'matches!inner(match_number)')
      .eq('call_to_mat', true)
      .eq('is_complete', false)
      .eq('event_id', eventId)
      .order('position')
      .returns<BracketWithWrestlersAndMatchNumbers[]>();

    // console.log('fetch brackets called to mat', data, error);

    return data || [];
  }

  async fetchBrackets(eventId: number, minType = 0, refreshEvent: RefresherCustomEvent | null = null) {
    let query = this.supabase
      .from(EVENT_BRACKETS_TABLE)
      .select('*, team1:dual_team1(id, name), team2:dual_team2(id, name), wrestlers:bracket_wrestlers!inner(*, wrestler:event_wrestler_id(*, team:team_id(*)))')
      .eq('event_id', eventId)
      .order('position')
      .order('position', {
        foreignTable: 'bracket_wrestlers',
        ascending: true,
      });

    if (minType > 0) {
      query = query.gte('type', minType);
    }
    const { data, error } = await query.returns<BracketWithWrestlers[]>(); // is this right?
    console.log('ALL BRACKETS', data, error);
    return data;
  }

  async loadBrackets(eventId: number, minType = 0, refreshEvent: RefresherCustomEvent | null = null) {
    let query = this.supabase
      .from(EVENT_BRACKETS_TABLE)
      .select(
        // '*, wrestlers:bracket_wrestlers!inner(*, wrestler:event_wrestler_id(*, team:team_id(*)))'
        '*, team1:dual_team1(id, name), team2:dual_team2(id, name), wrestlers:bracket_wrestlers(*,wrestler:event_wrestler_id(*, team:team_id(*)))',
      )
      .eq('event_id', eventId)
      .order('position')
      .order('position', {
        foreignTable: 'bracket_wrestlers',
        ascending: true,
      });
    if (minType > 0) {
      query = query.gte('type', minType);
    }
    const { data, error } = await query.returns<BracketWithWrestlers[]>();

    // if (
    //   data.length &&
    //   data[0].wrestlers.length &&
    //   data[0].wrestlers[0] === undefined &&
    //   retry
    // ) {
    //   console.log('ERROR LOADING BRACKET WRESTLERS?  Try again');
    //   return this.loadBrackets(eventId, (retry = false));
    // }

    if (refreshEvent) {
      refreshEvent.target.complete();
    }
    console.log('ALL BRACKETS', data, error);

    this.brackets$.next(JSON.parse(JSON.stringify(data)));
  }

  // TODO: not used?
  // async fetchSingleBracketByUUID(bracketUuid: string) {
  //   let query = this.supabase
  //     .from(EVENT_BRACKETS_TABLE)
  //     .select('*, wrestlers:bracket_wrestlers(*,wrestler:event_wrestler_id(*, team:team_id(*)))')
  //     .eq('uuid', bracketUuid)
  //     .returns<BracketWithWrestlers[]>()
  //     .single();

  //   const { data, error } = await query;
  //   return data;
  // }

  //todo: do we need division here?
  async saveBracket(eventId: number, bracket: BracketWithWrestlers): Promise<{ data: any; error: any } | null> {
    let wrestlers: BracketWrestler[] = bracket.wrestlers;

    let wrestlersForRPC = wrestlers.map((w, index) => {
      return {
        position: w.position ?? index,
        event_id: eventId,
        event_wrestler_id: w.event_wrestler_id,
        dual_team: w.dual_team == 2 ? 2 : 1,
      };
    });

    console.log('saving bracket', bracket);
    const result = await this.supabase.rpc('save_bracket', {
      _event_id: eventId,
      _bracket: bracket,
      _wrestlers: wrestlersForRPC,
    });

    //console.log('SAVE BRACKET RESULT', result);
    if (result.error) {
      console.log('failed to save bracket', result.error);
      return null;
    }

    return result;
    // let wrestlers = [...toInsert.wrestlers].map((w) => ({
    //   ...(w.wrestler ? w.wrestler : w),
    // }));

    // TODO: cascade deletes?

    // console.log('saving bracket', toInsert);
    // console.log('wrestler', wrestlers);
    // let finalInsert = JSON.parse(JSON.stringify(toInsert));
    // delete finalInsert['id'];
    // delete finalInsert['wrestlers'];
    // delete finalInsert['team1'];
    // delete finalInsert['team2'];
    // let res = await this.supabase.from(EVENT_BRACKETS_TABLE).upsert(finalInsert, { onConflict: 'uuid' }).select().returns<EventBracket[]>();
    // console.log(res);

    // console.log('SAVE BRACKET RSULT', res);
    // if (!res.data) {
    //   console.log('failed to save bracket', res.error);
    //   return;
    // }
    // let bracket_id = res.data[0].id;
    // let del = await this.supabase.from('bracket_wrestlers').delete().eq('bracket_id', bracket_id);
    // let res2 = await this.supabase.from('bracket_wrestlers').insert(
    //   wrestlers.map((w, index) => {
    //     return {
    //       position: w.position ?? index,
    //       event_id: eventId,
    //       event_wrestler_id: w.event_wrestler_id,
    //       dual_team: w.dual_team == 2 ? 2 : 1,
    //       // TODO in trigger
    //       // wrestler_id: w.wrestler_id,

    //       bracket_id,
    //     };
    //   }),
    // );
  }

  // async saveBracketOriginal(eventId: number, bracket: BracketWithWrestlers) {
  //   // need to re-calc num matches, etc.

  //   let wrestlers: BracketWrestler[] = bracket.wrestlers;

  //   let toInsert = {
  //     ...bracket,
  //     event_id: eventId,
  //     // num_matches: bracket.wrestlers.length > 1 ? bracket.wrestlers.length : 0,
  //     num_matches: bracket.num_matches ? bracket.num_matches : bracket.wrestlers.length, // TODO FIX!!
  //     rounds: bracket.wrestlers.length % 2 ? 3 : 2, // TODO FIX!
  //   };
  //   // let wrestlers = [...toInsert.wrestlers].map((w) => ({
  //   //   ...(w.wrestler ? w.wrestler : w),
  //   // }));

  //   // TODO: cascade deletes?

  //   console.log('saving bracket', toInsert);
  //   console.log('wrestler', wrestlers);
  //   let finalInsert = JSON.parse(JSON.stringify(toInsert));
  //   delete finalInsert['id'];
  //   delete finalInsert['wrestlers'];
  //   delete finalInsert['team1'];
  //   delete finalInsert['team2'];
  //   let res = await this.supabase.from(EVENT_BRACKETS_TABLE).upsert(finalInsert, { onConflict: 'uuid' }).select().returns<EventBracket[]>();
  //   console.log(res);

  //   console.log('SAVE BRACKET RSULT', res);
  //   if (!res.data) {
  //     console.log('failed to save bracket', res.error);
  //     return;
  //   }
  //   let bracket_id = res.data[0].id;
  //   let del = await this.supabase.from('bracket_wrestlers').delete().eq('bracket_id', bracket_id);
  //   let res2 = await this.supabase.from('bracket_wrestlers').insert(
  //     wrestlers.map((w, index) => {
  //       return {
  //         position: w.position ?? index,
  //         event_id: eventId,
  //         event_wrestler_id: w.event_wrestler_id,
  //         dual_team: w.dual_team == 2 ? 2 : 1,
  //         // TODO in trigger
  //         // wrestler_id: w.wrestler_id,

  //         bracket_id,
  //       };
  //     }),
  //   );
  // }

  async deleteSingleBracket(bracket: EventBracket) {
    await this.supabase.from(EVENT_BRACKETS_TABLE).delete().eq('uuid', bracket.uuid);
  }

  async deleteBrackets(eventId: number, division_id: number, keep_manually_added: boolean = false) {
    // if keep_manually_added is true, we need to only delete those with manually_added = false
    if (keep_manually_added) {
      await this.supabase.from(EVENT_BRACKETS_TABLE).delete().eq('event_id', eventId).eq('division_id', division_id).eq('manually_added', false);
    } else {
      await this.supabase.from(EVENT_BRACKETS_TABLE).delete().eq('event_id', eventId).eq('division_id', division_id);
    }
  }

  async updateBracketData(bracket_uuid: string, data: EventBracketUpdate) {
    let toInsert = {
      ...data,
    };
    let finalInsert = JSON.parse(JSON.stringify(toInsert));
    delete finalInsert['id'];
    delete finalInsert['wrestlers'];
    delete finalInsert['team1'];
    delete finalInsert['team2'];

    let res2 = await this.supabase.from(EVENT_BRACKETS_TABLE).update(finalInsert).match({ uuid: bracket_uuid });
    // console.log('tried to update bracket with data:', bracket_uuid, data);

    return res2;
  }

  // TODO: needs more work
  // async deleteSingleBracket(bracket) {
  //   await this.supabase
  //     .from('bracket_wrestlers')
  //     .delete()
  //     .eq('bracket_id', bracket.id);

  //   await this.supabase
  //     .from(EVENT_BRACKETS_TABLE)
  //     .delete()
  //     .eq('uuid', bracket.uuid);
  // }

  async saveSingleBracket(event_id: number, bracket: BracketWithWrestlers) {
    // Check online status first
    const isOnline = await this.networkService.onlineStatus$.pipe(take(1)).toPromise();
    if (!isOnline) {
      throw new Error('No internet connection available');
    }

    try {
      const result = await this.saveBracket(event_id, bracket);
      if (!result || result.error) {
        throw new Error('Failed to save bracket');
      }
      return result;
    } catch (error) {
      console.error('Error saving bracket:', error);
      throw error; // Re-throw to allow proper error handling in calling code
    }
  }

  async updateBracketPositions(brackets: BracketWithWrestlers[]) {
    await this.spinnerShow();

    const update: EventBracketUpdate[] = brackets.map((b, index) => {
      return { uuid: b.uuid, event_id: b.event_id, position: index };
    });

    // console.log('updateBracketPositions', update);
    const { data, error } = await this.supabase.from(EVENT_BRACKETS_TABLE).upsert(update, { onConflict: 'uuid' });

    await this.spinnerHide();
  }

  async saveBrackets(eventId: number, brackets: BracketWithWrestlers[]) {
    await this.spinnerShow();

    for (let i = 0; i < brackets.length; i++) {
      brackets[i].position = i;
      brackets[i].wrestlers = brackets[i].wrestlers.map((w, index) => {
        return {
          ...w,
          position: w.position ?? index,
          event_id: eventId,
          event_wrestler_id: w.event_wrestler_id,
          dual_team: w.dual_team == 2 ? 2 : 1,
        };
      });
    }

    let { data, error } = await this.supabase.rpc('save_all_brackets', {
      _event_id: eventId,
      _brackets: brackets,
    });

    await this.spinnerHide();

    // error is null on success, else an error object.  If we errored, return false.
    return !error;
  }

  setBracketTypeAndMatches(bracket: BracketWithWrestlers, bracketStyles: string) {
    this.setBracketType(bracket, bracketStyles);
    this.setBracketProperties(bracket);
  }
  setBracketType(bracket: BracketWithWrestlers, bracketStyles: string) {
    const len = bracket.wrestlers.length;
    console.log('bracket', bracket, 'syle:', bracketStyles);

    if (len == 0) {
      bracket['type'] = BracketTypes.Empty;
    }
    if (bracketStyles == 'medals') {
      // special case - winner loser brackets...
      if (len == 1) {
        bracket['type'] = BracketTypes.TwoMatch1Man;
      } else if (len == 2) {
        bracket['type'] = BracketTypes.Place2MP2;
      } else if (len == 3) {
        bracket['type'] = BracketTypes.Place3MRR;
      } else if (len == 4) {
        bracket['type'] = BracketTypes.Place4MP4;
      }
    } else if (bracketStyles == 'medalsRR') {
      // special case - winner loser brackets...
      if (len == 1) {
        bracket['type'] = BracketTypes.TwoMatch1Man;
      } else if (len == 2) {
        bracket['type'] = BracketTypes.Place2MP2;
      } else if (len == 3) {
        bracket['type'] = BracketTypes.Place3MRR;
      } else if (len == 4) {
        bracket['type'] = BracketTypes.Place4MRR;
      }
    } else if (bracketStyles == '2match') {
      if (len == 1) {
        bracket['type'] = BracketTypes.TwoMatch1Man;
      }
      if (len == 2) {
        bracket['type'] = BracketTypes.TwoMatch2Man;
      } else if (len == 3) {
        bracket['type'] = BracketTypes.TwoMatch3Man;
      } else if (len == 4) {
        bracket['type'] = BracketTypes.TwoMatch4Man;
      } else if (len == 5) {
        bracket['type'] = BracketTypes.TwoMatch5Man;
      }
    }
  }

  setBracketProperties(bracket: BracketWithWrestlers) {
    bracket['num_placers'] = 0;
    bracket['place_type'] = '';
    bracket['num_matches'] = 0;
    bracket['max_num_wrestlers'] = 0;

    switch (bracket.type) {
      case BracketTypes.Empty:
        bracket['num_matches'] = 0;
        bracket['max_num_wrestlers'] = 0;
        break;
      case BracketTypes.TwoMatch1Man:
        bracket['num_matches'] = 0;
        bracket['max_num_wrestlers'] = 1;
        break;
      case BracketTypes.Place2MP2:
        bracket['num_matches'] = 1;
        bracket['num_placers'] = 2;
        bracket['place_type'] = 'B';
        bracket['max_num_wrestlers'] = 2;
        break;
      case BracketTypes.Place3MRR:
        bracket['num_matches'] = 3;
        bracket['num_placers'] = 3;
        bracket['place_type'] = 'RR';
        bracket['max_num_wrestlers'] = 3;
        break;
      case BracketTypes.Place4MRR:
        bracket['num_matches'] = 6;
        bracket['num_placers'] = 4;
        bracket['place_type'] = 'RR';
        bracket['max_num_wrestlers'] = 4;
        break;
      // case BracketTypes.Place5MRR:
      //   // takes 5 rounds
      //   bracket['num_matches'] = 10;
      //   bracket['num_placers'] = 5;
      //   bracket['place_type'] = 'RR';
      //   bracket['max_num_wrestlers'] = 5;
      //   break;
      case BracketTypes.Place4MP4:
        bracket['num_matches'] = 4;
        bracket['num_placers'] = 4;
        bracket['place_type'] = 'B';
        bracket['max_num_wrestlers'] = 4;
        break;
      case BracketTypes.Place8MWB3P6:
        bracket['num_matches'] = 13;
        bracket['num_placers'] = 6;
        bracket['place_type'] = 'B';
        bracket['max_num_wrestlers'] = 8;
        break;
      case BracketTypes.Place16MWB3P6:
        bracket['num_matches'] = 29;
        bracket['num_placers'] = 6;
        bracket['place_type'] = 'B';
        bracket['max_num_wrestlers'] = 16;
        break;
      case BracketTypes.TwoMatch1Man:
        bracket['num_matches'] = 0;
        bracket['max_num_wrestlers'] = 1;
        break;
      case BracketTypes.TwoMatch2Man:
        bracket['num_matches'] = 2;
        bracket['max_num_wrestlers'] = 2;
        break;
      case BracketTypes.TwoMatch3Man:
        bracket['num_matches'] = 3;
        bracket['max_num_wrestlers'] = 3;
        break;
      case BracketTypes.TwoMatch4Man:
        bracket['num_matches'] = 4;
        bracket['max_num_wrestlers'] = 4;
        break;
      case BracketTypes.TwoMatch5Man:
        bracket['num_matches'] = 5;
        bracket['max_num_wrestlers'] = 5;
        break;
      // case BracketTypes.TwoMatch6Man:
      //   bracket['num_matches'] = 6;
      //   bracket['max_num_wrestlers'] = 6;
      //   break;
      case BracketTypes.Dual:
        bracket['num_matches'] = bracket.weight_classes?.length || 0;
        bracket['max_num_wrestlers'] = bracket.weight_classes?.length || 0;
        break;
    }
  }

  rawWrestlerBracketToFullObject(rawBracket: Array<GeneratorWrestler>, index: number, bracketStyles: string /*TODO: enum*/, division_id: number) {
    const len = rawBracket.length;

    let wrestlers: BracketWrestlerWithDetails[] = rawBracket.map((w) =>
      undefinedToNull<BracketWrestlerWithDetails>({
        event_wrestler_id: w.id,
      }),
    );
    let bracket: BracketWithWrestlers = undefinedToNull<BracketWithWrestlers>({
      id: index,
      uuid: this.getUuid4(),
      position: index,
      mat: 0,
      rounds: len == 3 || len == 5 ? 3 : 2,
      num_matches: len > 1 ? len : 0,
      wrestlers,
      num_placers: 0,
      place_type: '',
      division_id,
    });

    if (bracketStyles == 'medals') {
      bracket['name'] = `Medals #${index + 1}`;
      this.setBracketTypeAndMatches(bracket, bracketStyles);
    } else {
      bracket['name'] = `Group #${index + 1}`;
      this.setBracketTypeAndMatches(bracket, bracketStyles);
    }
    return bracket;
  }

  async showToast(message: string, options: ToastOptions = {}) {
    const defaults = { duration: 2000 };
    const toast = await this.toastController.create({
      ...defaults,
      ...options,
      message,
      cssClass: 'custom-toast',
    });
    await toast.present();
  }
  // searching:
  // // this.searchField.valueChanges.pipe(
  //   map((search) => search.trim()),
  //   debounceTime(200),
  //   distinctUntilChanged(),
  //   filter((search) => search !== ""),
  //   switchMap(search =>
  //     httpApi(search).pipe(retry(3).startWith([[]])))
  // )

  // concept: combinelatest to merge things (like foreign key constraints)
  // combined$ = combineLatest(this.wrestlers$, this.teams$).pipe(
  // map(([wrestlers, teams]) => wrestlers.map(p=> ({...p, team: teams.find(...)})))
  // )

  // to take action (like clicking button), we need an action stream
  // teamsChangedAction = new Subject<Teams[]>
  // teamsChangedAction$ = this.teamsChangedAction.asObservable()
  //
  // onSelect(teams[]) { this.service.changeSelectedTeams(teams)}
  // changeSelectedTeams(teams[]){ this.teamsSelectedAction.next(teams)}

  currentLeagueId: number | null = null;

  _currentLeagues = new BehaviorSubject<League[]>([]);
  currentLeagues$ = this._currentLeagues.asObservable();

  _currentLeague = new BehaviorSubject<LeagueWithAdmins | null>(null);
  currentLeague$ = this._currentLeague.asObservable();

  _currentWeightClasses = new BehaviorSubject<LeagueWeightClass[]>([]);
  currentWeightClasses$ = this._currentWeightClasses.asObservable();

  DEFAULT_AUTOGEN_SETTINGS = {
    age_gap: 2,
    skill_gap: 2,
    grade_gap: -1,
    same_team: 'disallow',
    weight_diff: 10,
    bracket_types: '2match',
    match_schedule: 'fifo',
    match_shift: 0,
    mixed_gender: true,
  } as EventAutogenSettings;

  _currentAutogenSettings = new BehaviorSubject<EventAutogenSettings>(this.DEFAULT_AUTOGEN_SETTINGS);
  currentAutogenSettings$ = this._currentAutogenSettings.asObservable();

  _currentTeamsList = new BehaviorSubject<Team[]>([]);
  currentTeamsList$ = this._currentTeamsList.asObservable();

  _currentLeagueEvents = new BehaviorSubject<null | LeagueEvent[]>([]);
  currentLeagueEvents$ = this._currentLeagueEvents.asObservable();

  isAdmin$ = combineLatest([this.currentLeague$, this.currentUser]).pipe(
    map(([league, user]) => {
      // console.log('isAdmin? ', league, user);

      let is_admin = false;
      // later, add check to league_admins table
      if (league && user) {
        // league might also have a league_admins array. If it does, check the user.id against league_admin.user_uuid.
        if (league.league_admins && league.league_admins.length) {
          is_admin = league.league_admins.some((admin) => admin.user_uuid == user.id);
        }

        return is_admin || user.id == league.admin_id || user.email == 'robert@erafx.com';
      }
      return false;
    }),
  );

  _currentTeam = new BehaviorSubject<Team | null>({
    id: 0,
  } as Team);
  currentTeam$ = this._currentTeam.asObservable();

  _currentTeamWrestlers = new BehaviorSubject<Wrestler[]>([]);
  currentTeamWrestlers$ = this._currentTeamWrestlers.asObservable();

  _currentLeagueWrestlers = new BehaviorSubject<Wrestler[]>([]);
  currentLeagueWrestlers$ = this._currentLeagueWrestlers.asObservable();

  _currentEvent = new BehaviorSubject<null | LeagueEvent>(null);
  currentEvent$ = this._currentEvent.asObservable();
  _currentEventTeams = new BehaviorSubject<TeamsJoinedEventTeams[]>([]);
  currentEventTeams$ = this._currentEventTeams.asObservable();

  _currentAllTeamsInEventList = new BehaviorSubject<Team[]>([]);
  currentAllTeamsInEventList$ = this._currentAllTeamsInEventList.asObservable();

  _currentBout = new BehaviorSubject<null | MatchWithWrestlersAndSettingsAndBracket>(null);
  currentBout$ = this._currentBout.asObservable();

  _currentBoutEvents = new BehaviorSubject<MatchActivity[]>([]);
  currentBoutEvents$ = this._currentBoutEvents.asObservable();

  _currentEventWrestlersFromTeams = new BehaviorSubject<WrestlerWithTeam[]>([]);
  currentEventWrestlersFromTeams$ = this._currentEventWrestlersFromTeams.asObservable();

  _currentEventWrestlers = new BehaviorSubject<EventWrestlerWithTeamAndDivisions[]>([]); // with team?  TODO: check it
  currentEventWrestlers$ = this._currentEventWrestlers.asObservable();

  _currentEventMatches = new BehaviorSubject<MatchWithWrestlers[]>([]);
  currentEventMatches$ = this._currentEventMatches.asObservable();

  _currentUserNotifications = new BehaviorSubject<MatNotification[]>([]);
  currentUserNotifications$ = this._currentUserNotifications.asObservable();

  async updateCurrentUserNotification(eventId: number) {
    const userid = this._currentUser.value.id;
    // console.log('get notifs for user id', userid);

    this._currentUserNotifications.next(await this.getUserMatNotifications(userid, eventId));
  }

  async updateCurrentLeaguesDebugForMeOnly() {
    this._currentLeagues.next(await this.getAllLeagues());
  }

  async updateCurrentLeagues(onlyPublic = true) {
    this._currentLeagues.next(await this.getLeagues(onlyPublic));
  }

  async updateCurrentEventMatches(eventId: string, mat: number | null = null, refreshEvent: RefresherCustomEvent | null = null) {
    try {
      // Try to fetch from server first
      const matches = await this.getEventMatches(eventId, mat);

      // If successful and we have a mat number, cache the matches
      if (mat !== null) {
        await this.offlineManager.cacheMatches(eventId, mat, matches);
      }

      this._currentEventMatches.next(matches);
    } catch (error) {
      // On failure, try to load from cache if we have a mat number
      if (mat !== null) {
        const cachedMatches = await this.offlineManager.getCachedMatches(eventId, mat);
        if (cachedMatches) {
          this._currentEventMatches.next(cachedMatches);
        }
      }
    }

    if (refreshEvent && refreshEvent.target) {
      refreshEvent.target.complete();
    }
  }

  async updateCurrentEventWrestlers(eventId: number) {
    this._currentEventWrestlers.next(await this.getEventOfficialWrestlers(eventId.toString()));
  }

  async updateCurrentTeam(teamId: number) {
    this._currentTeam.next(await this.getTeam(teamId.toString()));
  }

  async updateCurrentEventTeams(eventId: number) {
    console.log('updating event teams... id is ', eventId);

    this._currentEventTeams.next(await this.getEventTeams(eventId.toString()));
  }

  async updateCurrentBout(eventId: number, match_number: string) {
    this._currentBout.next(await this.getBoutForEvent(eventId.toString(), match_number));
  }
  async updateCurrentBoutEvents(boutId: number) {
    this._currentBoutEvents.next(await this.getBoutEvents(boutId));
  }
  async updateCurrentEvent(eventId: number) {
    console.log('updating current event for id', eventId);

    this._currentEvent.next(await this.getEvent(eventId.toString()));
  }

  async updateCurrentLeagueWrestlersFromTeams(teams: number[]) {
    console.log('updateCurrentLeagueWrestlersFromTeams');

    this._currentLeagueWrestlers.next(await this.getTeamsWrestlers(teams));
  }

  async updateCurrentEventWrestlersFromTeams(teams: number[]) {
    console.log('updateCurrentEventWrestlersFromTeams');

    this._currentEventWrestlersFromTeams.next(await this.getTeamsWrestlers(teams));
  }

  async searchLeagues(term: string) {
    const { data, error } = await this.supabase
      .from(LEAGUES_TABLE)
      .select('*')
      .eq('public', true)
      .like('name', '%' + term + '%');
  }
  async getAllLeagues() {
    const { data, error } = await this.supabase.from(LEAGUES_TABLE).select('*').order('name');

    if (error) {
      this.showToast('Unable to load leagues');
    }

    return data || [];
  }

  // TODO add specifier to get only active leagues (current, etc)
  async getLeagues(onlyPublic = true) {
    const { data, error } = await this.supabase.from(LEAGUES_TABLE).select('*').eq('public', onlyPublic).order('name');
    if (error) {
      this.showToast('Unable to load leagues');
    }
    return data || [];
  }

  async insertUserNotif(event_id: number, owner_id: number, team_id: number, mat: number, token: string) {
    if (!owner_id || !token) {
      this.showToast('Unable to register notification', { duration: 3000 });
    } else {
      return this.supabase.from(NOTIF_TABLE).insert({ owner_id, event_id, team_id, mat, token });
    }
  }

  async deleteUserNotif(id: number) {
    let r = await this.supabase.from(NOTIF_TABLE).delete().eq('id', id);
    return r;
  }

  async getLeagueRankRecommendations(league_id: number) {
    await this.spinnerShow();
    let r = await this.supabase.rpc('get_wrestler_rank_recommendations_with_team', { p_league_id: league_id, p_min_matches: 4 });

    console.log('getLeagueRankRecommendations', r);
    await this.spinnerHide();
    return r.data || [];
  }

  async getEventMatNotifications(event_id: number) {
    const { data, error } = await this.supabase.from(NOTIF_TABLE).select('*').eq('event_id', event_id);

    return data || [];
  }
  async getUserMatNotifications(userid: number, eventId: number) {
    const { data, error } = await this.supabase.from(NOTIF_TABLE).select('*').eq('owner_id', userid).eq('event_id', eventId);

    return data || [];
  }
  async getWrestlerWeightHistory(id: number) {
    let res = await this.supabase.from(EVENT_WRESTLERS_TABLE).select('new_weight, weighed_at').eq('wrestler_id', id).order('weighed_at', { ascending: false }).returns<WeightHistory[]>();

    return res.data || [];
  }

  async getWrestlerCompositeHistory(id: number) {
    let res = await this.supabase.from(EVENT_WRESTLERS_TABLE).select('composite').eq('wrestler_id', id).order('weighed_at', { ascending: true });
    return res.data || [];
  }

  async getWrestlerEventStats(id: number) {
    let res = await this.supabase.from('events_for_per_wrestler').select('*').eq('wrestler_for', id).returns<EventsForPerWrestlerView[]>();
    return res.data || [];
  }

  async getTeamStatLeaders(team_id: number) {
    let res = await this.supabase.from('team_stat_leaders').select('*, wrestler:wrestler_for(first_name, last_name)').eq('team_id', team_id).returns<TeamStatsViewWithWrestlerName[]>();
    // .in('event', ['pin, T2']);

    // console.log(res.data);

    return res.data || [];
  }

  async getWrestlerEventStatsAgainst(id: number) {
    let res = await this.supabase.from('events_against_per_wrestler').select('*').eq('wrestler_against', id).returns<EventsAgainstPerWrestlerView[]>();

    return res.data || [];
  }

  async getWrestlerMatches(id: number) {
    let res = await this.supabase.from(EVENT_WRESTLERS_TABLE).select('id').eq('wrestler_id', id);
    if (res.data) {
      const ids = res.data.map((row) => row.id);

      type internalMatchWithEventAndWrestlers = MatchWithWrestlers & {
        wrestler1a: WrestlerWithTeam | null;
        wrestler2a: WrestlerWithTeam | null;
        event: Pick<LeagueEvent, 'event_start_date' | 'name'>;
      };
      const { data, error } = await this.supabase
        .from(MATCHES_TABLE)
        .select(
          '*, ' +
            'event:league_event_id(event_start_date, name),' +
            'wrestler1a:wrestler1(id, first_name, last_name, wrestler_id, composite, team:team_id(id, name)), ' +
            'wrestler2a:wrestler2(id, first_name, last_name, wrestler_id, composite, team:team_id(id, name)) ',
        )
        .or(`wrestler1.in.(${ids.join(',')}),wrestler2.in.(${ids.join(',')})`)
        // TODO CHECKME
        .order('id')
        .returns<internalMatchWithEventAndWrestlers[]>();
      // .order('match_number');
      if (error) {
        this.showToast('Unable to load matches');
      }
      if (data) {
        // convert to proper format:
        let matches = data.map(
          (m) =>
            ({
              ...m,
              wrestler1: m.wrestler1a,
              wrestler2: m.wrestler2a,
            }) as MatchWithEventAndWrestlers,
        );

        matches.sort((m1, m2) => {
          // Check for null event_start_date
          if (m1.event.event_start_date === null && m2.event.event_start_date !== null) {
            return -1;
          }
          if (m1.event.event_start_date !== null && m2.event.event_start_date === null) {
            return 1;
          }
          if (m1.event.event_start_date === null && m2.event.event_start_date === null) {
            return 0;
          }

          // At this point, neither event_start_date should be null.
          const dateCompare = m1.event.event_start_date!.localeCompare(m2.event.event_start_date!);

          if (dateCompare !== 0) {
            return dateCompare;
          }

          // Check for null match_number
          if (m1.match_number === null && m2.match_number !== null) {
            return -1;
          }
          if (m1.match_number !== null && m2.match_number === null) {
            return 1;
          }
          if (m1.match_number === null && m2.match_number === null) {
            return 0;
          }

          // At this point, neither match_number should be null.
          return m1.match_number! - m2.match_number!;
        });

        return matches;
      }
      // console.log('we got matches for wrestler', data);
    } else {
      if (res.error) {
        this.showToast('Unable to load matches');
      }
      return [];
    }
  }

  async getEventTeams(eventId: string) {
    const { data, error } = await this.supabase
      .from(TEAMS_TABLE)
      .select('*,' + EVENT_TEAMS_TABLE + '!inner(*)')
      .eq(EVENT_TEAMS_TABLE + '.event_id', eventId)
      .order('name')
      .returns<TeamsJoinedEventTeams[]>();

    console.log('EVENT TEAMS: ', data);

    return data || ([] as TeamsJoinedEventTeams[]);
  }

  async getAllTeamsInEvent(eventId: string) {
    console.log('get all teams in event');

    const list1 = await this.getEventTeams(eventId);
    const list2 = await this.getAllTeamsFromEventWrestlersTable(eventId);

    let unique2 = list2.filter((team) => {
      return !list1.find((t) => t.id == team.id);
    });

    const combined = [...list1, ...unique2].sort((a, b) => {
      // Handle null values
      if (a.name === null && b.name !== null) {
        return -1;
      }
      if (a.name !== null && b.name === null) {
        return 1;
      }
      if (a.name === null && b.name === null) {
        return 0;
      }

      // At this point, neither name should be null.
      return a.name!.localeCompare(b.name!);
    });

    return combined || [];
  }

  async getAllTeamsFromEventWrestlersTable(eventId: string) {
    const lists = await this.supabase
      .from(TEAMS_TABLE)
      .select('*,' + EVENT_WRESTLERS_TABLE + '!inner(*)')
      .eq(EVENT_WRESTLERS_TABLE + '.event_id', eventId)
      .order('name')
      .returns<TeamsWithEventWrestlers[]>();

    console.log('ALL EVENT TEAMS: ', lists.data);

    return lists.data || [];
  }

  async loadBracketsWithMatches(eventId: string) {
    this.bracketsFullWithMatches$.next(await this.getBracketsWithMatches(eventId));
  }

  async getBracketsWithMatches(eventId: string) {
    const lists = await this.supabase
      .from(EVENT_BRACKETS_TABLE)
      .select(
        '*,' +
          MATCHES_TABLE +
          '!inner(*,' +
          'wrestler1(id, first_name, last_name,  wrestler_id, team_id, team:team_id(id, name)), ' +
          'wrestler2(id, first_name, last_name, wrestler_id, team_id, team:team_id(id, name)) ' +
          ')',
      )
      .eq(MATCHES_TABLE + '.league_event_id', eventId)
      .returns<BracketWithMatches[]>();

    console.log('ALL EVENT brackets: ', lists.data);

    return lists.data || [];
  }

  async getEventMatches(eventId: string, mat: number | null = null) {
    console.log('get event matches for event id', eventId, 'mat', mat);
    let query = this.supabase
      .from(MATCHES_TABLE)
      .select('*,' + 'wrestler1(id, first_name, last_name,  wrestler_id, team_id, team:team_id(id, name)), ' + 'wrestler2(id, first_name, last_name, wrestler_id, team_id, team:team_id(id, name)) ')
      .eq('league_event_id', eventId);

    if (mat) {
      query = query.eq('mat', mat);
    }

    query = query.order('match_number');

    const lists = await query.returns<MatchWithWrestlers[]>();
    console.log('we got matches', lists.data);
    if (lists.error) {
      this.showToast('Failed to load matches');
    }
    return lists.data || [];
  }

  async getEventWeighedInWrestlersForDivision(eventId: number, division_id: number) {
    const lists = await this.supabase
      .from(EVENT_WRESTLERS_TABLE)
      .select(
        `
        *,
        team:team_id(*),
        event_wrestler_divisions!inner(division_id)
      `,
      )
      .eq('event_id', eventId)
      .eq('event_wrestler_divisions.division_id', division_id)
      .eq('is_weighed_in', true)
      .returns<EventWrestlerWithTeam[]>()
      .order('team_id, first_name, last_name');

    // console.log('we got weighed in wrestlers', lists.data);

    return lists.data || [];
  }

  async getEventOfficialWrestlers(eventId: string) {
    // TODO: divisions
    const lists = await this.supabase
      .from(EVENT_WRESTLERS_TABLE)
      .select('*, team:team_id(*), divisions:event_wrestler_divisions!inner(division_id)')
      .eq('event_id', eventId)
      .returns<EventWrestlerWithTeamAndDivisions[]>()
      .order('team_id, first_name, last_name');

    // console.log('we got official wrestlers', lists.data);

    return lists.data || [];
  }

  async getLeagueTeams(leagueId: string): Promise<Team[]> {
    const lists = await this.supabase.from(TEAMS_TABLE).select('*').eq('league_id', leagueId).returns<Team[]>().order('name');

    if (lists.error) {
      this.showToast('Failed to load teams');
    }
    return lists.data || [];
  }

  async getLeagueWeightClasses(leagueId: string) {
    const { data, error } = await this.supabase.from(LEAGUE_WEIGHT_CLASSES_TABLE).select('*').eq('league_id', leagueId).returns<LeagueWeightClass[]>().order('weight');
    return data || [];
  }

  // there might be multiple now -- one per division...
  async getEventAutogenSettings(event_id: number, division_id: number) {
    const { data, error } = await this.supabase.from(EVENT_AUTOGEN_SETTINGS_TABLE).select('*').eq('event_id', event_id).eq('division_id', division_id).returns<EventAutogenSettings>().single();
    // console.log('getEventAutogenSettings', data);
    return (
      data ||
      ({
        age_gap: 2,
        bracket_types: '2match',
        skill_gap: 2,
        grade_gap: -1,
        same_team: 'disallow',
        weight_diff: 10,
        match_schedule: 'fifo',
        match_shift: 0,
        mixed_gender: true,
        event_id,
        division_id,
        uuid: this.getUuid4(),
      } as EventAutogenSettings)
    );
  }

  async saveEventAutogenSettings(event_id: number, settings: EventAutogenSettings) {
    const { data, error } = await this.supabase
      .from(EVENT_AUTOGEN_SETTINGS_TABLE)
      .upsert({ ...settings, event_id }, { onConflict: 'uuid' })
      .returns<EventAutogenSettings>()
      .single();
    return data;
  }

  async saveLeagueStandardMats(leagueId: number, standard_mats: number[]) {
    console.log('saveLeagueStandardMats', leagueId, standard_mats);
    const { data, error } = await this.supabase.from(LEAGUES_TABLE).update({ standard_mats }).eq('id', leagueId).select();
    console.log('saveLeagueStandardMats', data, error);
  }

  async saveEventMats(eventId: number, available_mats: number[]) {
    await this.supabase.from(LEAGUE_EVENTS_TABLE).update({ available_mats }).eq('id', eventId).select();
  }

  async saveWeightClasses(leagueId: number, weightClasses: LeagueWeightClass[]) {
    // delete all weight classes for this league, then insert new ones
    // set leagueid on all weight classes

    weightClasses.forEach((wc) => {
      wc.league_id = +leagueId;
    });

    await this.supabase.from(LEAGUE_WEIGHT_CLASSES_TABLE).delete().eq('league_id', leagueId);

    const { data, error } = await this.supabase.from(LEAGUE_WEIGHT_CLASSES_TABLE).insert(
      weightClasses.map((wc) => ({
        league_id: wc.league_id,
        weight: wc.weight,
      })),
    );

    // TODO: error handling...
  }

  async getEventMatchDurations(leagueId: string) {
    let { data, error } = await this.supabase.from('event_match_durations_with_mats_by_league').select('*').eq('league_id', leagueId);

    if (error) {
      this.showToast('Failed to load event match durations');
    }

    return data || [];
  }

  async getLeagueEvents(leagueId: string) {
    const { data, error } = await this.supabase.from(LEAGUE_EVENTS_TABLE).select('*').eq('league_id', leagueId).returns<LeagueEvent[]>().order('event_start_date').order('name');

    // console.log('data:', data);
    // console.log('error:', error);

    return data || ([] as LeagueEvent[]);
  }

  async getEvent(eventId: string) {
    // TODO FIXME with type, and implement...
    const { data, error } = await this.supabase.from(LEAGUE_EVENTS_TABLE).select('*, linked:linked_league(*)').eq('id', eventId).returns<LeagueEventWithLinks[]>().limit(1).single();

    return data;
  }

  async deleteSelfUser() {
    const res = await this.supabase.rpc('deleteUser');
    console.log(res);
    if (res.error) {
      if (res.error.details.includes('referenced')) {
        await this.showToast("Unable to delete account, you're still an admin");
      } else {
      }
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  async deleteWrestler(w: Wrestler) {
    // if added to an event, can't delete...
    let d = await this.supabase.from(EVENT_WRESTLERS_TABLE).select('*', { count: 'exact', head: true }).eq('wrestler_id', w.id);
    console.log('FROM DELETE', d);

    if (d.status == 200) {
      if (d.count == 0) {
        // delete
        console.log('delete by id', w);

        let r = await this.supabase.from(WRESTLERS_TABLE).delete().eq('id', w.id);
        console.log(r);

        this.showToast(`Deleted ${w.first_name} ${w.last_name}`);
        if (w.team_id) {
          await this.updateCurrentTeamsWrestlers(w.team_id);
        }
      } else {
        this.showToast(`${w.first_name} ${w.last_name} was in events, cannot delete`);
      }
    } else {
      this.showToast(`Error: unable to delete ${w.first_name} ${w.last_name}`);
    }
  }

  async updateWrestler(wrestler: Wrestler) {
    return await this.supabase
      .from(WRESTLERS_TABLE)
      .update({ ...wrestler })
      .match({ id: wrestler.id });
  }

  async createWrestlers(teamId: number, listOfWrestlers: WrestlerImport[]) {
    const submitWrestlers = listOfWrestlers.map((v) => ({
      first_name: v.first_name,
      last_name: v.last_name,
      dob: v.dob,
      weight: v.weight,
      rank: v.rank,
      team_id: teamId,
      gender: v.gender,
      grade: v.grade,
    }));

    return this.supabase.from(WRESTLERS_TABLE).insert(submitWrestlers);
  }

  async deleteEvent(eventId: number): Promise<boolean> {
    await this.spinnerShow();
    const { error } = await this.supabase.from(LEAGUE_EVENTS_TABLE).delete().eq('id', eventId);

    await this.spinnerHide();

    if (error) {
      console.error('Error deleting event:', error);
      return false;
    }
    return true;
  }

  async deleteEventMatches(eventId: number): Promise<boolean> {
    try {
      const { error } = await this.supabase.from(MATCHES_TABLE).delete().eq('league_event_id', eventId);

      if (error) {
        console.error('Error deleting event matches:', error);
        this.showToast('Failed to delete event matches', { duration: 3000 });
        return false;
      }

      // Verify deletion
      const { count, error: countError } = await this.supabase.from(MATCHES_TABLE).select('*', { count: 'exact', head: true }).eq('league_event_id', eventId);

      if (countError) {
        console.error('Error verifying match deletion:', countError);
        this.showToast('Failed to verify match deletion', { duration: 3000 });
        return false;
      }

      if (count && count > 0) {
        console.warn(`Deletion incomplete: ${count} matches remaining`);
        this.showToast('Match deletion may be incomplete', { duration: 3000 });
        return false;
      }

      return true;
    } catch (error) {
      console.error('Unexpected error in deleteEventMatches:', error);
      this.showToast('An unexpected error occurred', { duration: 3000 });
      return false;
    }
  }

  async deleteEventWrestlers(eventId: number) {
    const { error } = await this.supabase.from(EVENT_WRESTLERS_TABLE).delete().eq('event_id', eventId);
  }

  async deleteEventTeams(eventId: number) {
    const { error } = await this.supabase.from(EVENT_TEAMS_TABLE).delete().eq('event_id', eventId);
    // console.log(error);
  }

  async deleteEventBrackets(eventId: number) {
    const { error } = await this.supabase.from(EVENT_BRACKETS_TABLE).delete().eq('event_id', eventId);
  }

  async setEventTeams(eventId: number, teams: Team[]) {
    const { error } = await this.supabase.from(EVENT_TEAMS_TABLE).delete().eq('event_id', eventId);
    let res = await this.supabase.from(EVENT_TEAMS_TABLE).insert(
      teams.map((team) => {
        return { event_id: eventId, team_id: team.id };
      }),
    );
  }
  async getTeamsWrestlers(teamIds: number[]) {
    // console.log('ftech wrestlers for teams: ', teamIds);
    if (teamIds.length == 0) {
      return [];
    }

    const lists = await this.supabase.from(WRESTLERS_TABLE).select('*, team:team_id(*)').in('team_id', teamIds).returns<WrestlerWithTeam[]>().order('last_name');

    // TODO figure out why being called multiple times...
    // console.log('we got team wrestlers from supabase', lists.data);

    return lists.data || [];
  }

  async getWrestler(wrestlerId: string) {
    const lists = await this.supabase.from(WRESTLERS_TABLE).select('*,team:team_id(name)').eq('id', wrestlerId).returns<WrestlerWithTeam[]>().limit(1).single();

    console.log('getWrestler', lists.data);
    return lists.data;
  }

  async getTeamWrestlers(teamId: string) {
    const lists = await this.supabase.from(WRESTLERS_TABLE).select('*').eq('team_id', teamId).returns<Wrestler[]>().order('last_name');

    return lists.data || [];
  }

  async getTeam(teamId: string) {
    const data = await this.supabase.from(TEAMS_TABLE).select('*').match({ id: teamId }).returns<Team[]>().limit(1).single();
    return data.data;
  }

  async removeLeagueAdmin(leagueId: number, userId: string) {
    const res = await this.supabase.from('league_admins').delete().match({ league_id: leagueId, user_uuid: userId });
    return res;
  }
  // async getLeagueAdmins(leagueId: number) {
  //   const data = await this.supabase
  //     .from('league_admins')
  //     .select('*')
  //     .match({ league_id: leagueId })
  //     .returns<LeagueAdmins[]>();
  //   return data.data;
  // }

  async getLeagueAdminsFull(leagueId: number) {
    const data = await this.supabase.from('league_admins').select('*, user: user_uuid(*)').match({ league_id: leagueId }).returns<LeagueAdminsFull[]>();
    return data.data;
  }

  async getDivision(division_id: number): Promise<Division | null> {
    const { data, error } = await this.supabase.from(DIVISIONS_TABLE).select('*').eq('id', division_id).returns<Division[]>().single();

    if (error) {
      console.error('Error fetching division:', error);
      return null;
    }
    return data;
  }

  async getLeagueDivisions(leagueId: number) {
    const { data, error } = await this.supabase
      .from(LEAGUE_DIVISIONS_TABLE)
      .select(
        `
        *,
        division: division_id(
          *,
          match_settings: match_settings_id(*)
        )
      `,
      )
      .eq('league_id', leagueId)
      // .order('is_system', { ascending: true })
      // .order('position', { ascending: true })
      .returns<LeagueDivision[]>();

    if (error) {
      console.error('Error fetching league divisions:', error);
      return [];
    }

    data.sort((a, b) => {
      console.log('a:', a);
      console.log('b:', b);
      if (a.division.is_system && !b.division.is_system) {
        return 1;
      }
      if (!a.division.is_system && b.division.is_system) {
        return -1;
      }
      const aPosition = a.division.position ?? 0;
      const bPosition = b.division.position ?? 0;
      return aPosition - bPosition;
    });
    return data;
  }

  // Not currently used.
  // async getLeagueDefaultDivisionMatchSettings(leagueId: number) {
  //   const { data, error } = await this.supabase
  //     .from(LEAGUE_DIVISIONS_TABLE)
  //     .select(
  //       `
  //       *,
  //       division: division_id(
  //         *,
  //         match_settings: match_settings_id(*)
  //       )
  //     `,
  //     )
  //     .eq('league_id', leagueId)
  //     .eq('is_system', true)
  //     .returns<LeagueDivision[]>();

  //   if (error) {
  //     console.error('Error fetching league divisions:', error);
  //     return [];
  //   }

  //   return data;
  // }

  // async getEventDefaultDivisionMatchSettings(eventId: number) {
  //   const { data, error } = await this.supabase
  //     .from(EVENT_DIVISIONS_TABLE)
  //     .select(
  //       `
  //       *,
  //       division: division_id(
  //         *,
  //         match_settings: match_settings_id(*)
  //       )
  //     `,
  //     )
  //     .eq('event_id', eventId)
  //     .eq('is_system', true)
  //     .returns<EventDivision[]>();

  //   if (error) {
  //     console.error('Error fetching event divisions:', error);
  //     return [];
  //   }

  //   return data;
  // }

  // saveLeagueDivision, similar to saveLeagueMatchSettings
  async saveLeagueDivision(leagueId: number, division: Division): Promise<boolean> {
    let match_settings_id = division.match_settings_id;
    if (!division.match_settings_id) {
      // add a default
      let matchSettings = await this.saveLeagueMatchSettings(leagueId, {
        league_id: leagueId,
        name: division.name + ' Default',
      });
      if (matchSettings) {
        match_settings_id = matchSettings.id;
        division.match_settings_id = matchSettings.id;
      }
    }

    let { data, error } = await this.supabase.from(DIVISIONS_TABLE).upsert(division, { onConflict: 'id' }).select().single();

    if (error) {
      console.error('Error saving division:', error);
      return false;
    }

    const divisionId = data.id;

    const { error: leagueDivisionError } = await this.supabase.from(LEAGUE_DIVISIONS_TABLE).upsert(
      {
        league_id: leagueId,
        division_id: divisionId,
      },
      { onConflict: 'league_id,division_id' },
    );

    if (leagueDivisionError) {
      console.error('Error saving league division:', leagueDivisionError);
      // delete the division if we failed to save the league division
      await this.supabase.from(DIVISIONS_TABLE).delete().match({ id: divisionId });
      return false;
    }

    return true;
  }

  async saveLeagueMatchSettings(leagueId: number, settings: MatchSettingsUpdate): Promise<MatchSettings | null> {
    // TODO: turn this into RPC

    let { data, error } = await this.supabase
      .from(MATCH_SETTINGS_TABLE)
      .upsert({ ...settings, league_id: leagueId }, { onConflict: 'id' })
      .select()
      .single();

    if (error) {
      console.error('Error saving match settings:', error);
      return null;
    }

    return data;
  }

  async deleteLeagueMatchSettings(league_id: number, match_settings_id: number): Promise<boolean> {
    // also now delete from MATCH_SETTINGS_TABLE
    const { error: matchSettingsError } = await this.supabase.from(MATCH_SETTINGS_TABLE).delete().match({ id: match_settings_id });

    return true;
  }

  async saveEventMatchSettings(leagueId: number, eventId: number, settings: MatchSettingsUpdate): Promise<MatchSettings | null> {
    let { data, error } = await this.supabase
      .from(MATCH_SETTINGS_TABLE)
      .upsert({ ...settings, league_id: leagueId, event_id: eventId }, { onConflict: 'id' })
      .select()
      .single();

    if (error) {
      console.error('Error saving match settings:', error);
      return null;
    }

    return data;
  }

  async deleteEventMatchSettings(event_id: number, match_settings_id: number): Promise<boolean> {
    // also now delete from MATCH_SETTINGS_TABLE
    const { error: matchSettingsError } = await this.supabase.from(MATCH_SETTINGS_TABLE).delete().match({ id: match_settings_id });

    return true;
  }

  async getEventDivisions(eventId: number): Promise<EventDivision[]> {
    const { data, error } = await this.supabase
      .from(EVENT_DIVISIONS_TABLE)
      .select(
        `
        *,
        division: division_id(
          *,
          match_settings: match_settings_id(*)
        )
      `,
      )
      .eq('event_id', eventId)
      .returns<EventDivision[]>();

    if (error) {
      console.error('Error fetching event divisions:', error);
      return [];
    }

    // if we got data, we need to sort the results since sorting referenced tables in the query doesn't work
    // Sort by division is_system asc, position asc
    data.sort((a, b) => {
      console.log('a:', a);
      console.log('b:', b);
      if (a.division.is_system && !b.division.is_system) {
        return 1;
      }
      if (!a.division.is_system && b.division.is_system) {
        return -1;
      }
      const aPosition = a.division.position ?? 0;
      const bPosition = b.division.position ?? 0;
      return aPosition - bPosition;
    });

    return data || [];
  }

  async getEventDivisionsWithEventWrestlers(eventId: number) {
    const { data, error } = await this.supabase
      .from('event_divisions')
      .select(
        `
        *,
        division:division_id (
          *,
          match_settings:match_settings_id(*),
          event_wrestler_divisions (
            event_wrestler: event_wrestler_id (
              *, team:team_id(*)
            )
          )
        )
      `,
      )
      .eq('event_id', eventId)
      // .order('order', { referencedTable: 'division', ascending: false })
      .order('division(position)', { ascending: false }) // if we upgrade postgrest, this should work.
      .returns<EventDivisionFullNested[]>();

    if (error) {
      console.error('Error fetching event divisions:', error);
      return [];
    }
    console.log('event divisions:', data);
    return data;
  }
  async updateWrestlerDivision(wrestlerId: number, divisionId: number) {
    const { data, error } = await this.supabase.from('event_wrestler_divisions').update({ division_id: divisionId }).eq('event_wrestler_id', wrestlerId);

    if (error) {
      console.error('Error updating wrestler division:', error);
      this.showToast('Failed to update wrestler division', { duration: 3000 });
      return null;
    }

    return data;
  }

  // is this, with joins, better?
  // async function getEventDivisionsWithEventWrestlers(eventId: number) {
  //   const { data, error } = await supabase
  //     .from('divisions')
  //     .select(`
  //       *,
  //       event_divisions!inner(
  //         *,
  //         event_wrestler_divisions(
  //           event_wrestler:event_wrestler_id(
  //             *, team:team_id(*)
  //           )
  //         )
  //       )
  //     `)
  //     .eq('event_divisions.event_id', eventId)
  //     .order('order', { ascending: true })
  //     .returns<EventDivisionFullNested[]>();

  //   if (error) {
  //     console.error('Error fetching event divisions:', error);
  //     return [];
  //   }

  //   console.log('event divisions:', data);
  //   return data;
  // }

  async addWrestlerToDivision(eventWrestlerId: number, divisionId: number) {
    const { data, error } = await this.supabase.from('event_wrestler_divisions').insert({ event_wrestler_id: eventWrestlerId, division_id: divisionId });

    if (error) {
      console.error('Error adding wrestler to division:', error);
      this.showToast('Failed to add wrestler to division', { duration: 3000 });
      return null;
    }

    return data;
  }

  async getAvailableWrestlersForDivision(eventId: number, divisionId: number) {
    const { data, error } = await this.supabase
      .from('event_wrestlers')
      .select(
        `
        *,
        team:team_id (*),
        event_wrestler_divisions!left (
          division_id
        )
      `,
      )
      .eq('event_id', eventId)
      .is('event_wrestler_divisions.division_id', null);

    if (error) {
      console.error('Error fetching available wrestlers:', error);
      this.showToast('Failed to fetch available wrestlers', { duration: 3000 });
      return [];
    }

    return data as EventWrestlerWithTeam[];
  }
  async saveEventDivision(leagueId: number, eventId: number, division: Division): Promise<boolean> {
    if (!division.match_settings_id) {
      // add a default
      let matchSettings = await this.saveEventMatchSettings(leagueId, eventId, {
        league_id: leagueId,
        name: division.name + ' Default',
      });
      if (matchSettings) {
        division.match_settings_id = matchSettings.id;
      }
    }

    let { data, error } = await this.supabase.from(DIVISIONS_TABLE).upsert(division, { onConflict: 'id' }).select().single();

    if (error) {
      console.error('Error saving division:', error);
      return false;
    }

    const divisionId = data.id;

    const { error: eventDivisionError } = await this.supabase.from(EVENT_DIVISIONS_TABLE).upsert(
      {
        event_id: eventId,
        division_id: divisionId,
      },
      { onConflict: 'event_id,division_id' },
    );

    if (eventDivisionError) {
      console.error('Error saving event division:', eventDivisionError);
      // delete the division if we failed to save the event division
      await this.supabase.from(DIVISIONS_TABLE).delete().match({ id: divisionId });
      return false;
    }

    return true;
  }

  async deleteLeagueDivision(league_id: number, division_id: number): Promise<boolean> {
    const { error } = await this.supabase.from(DIVISIONS_TABLE).delete().match({ league_id, id: division_id });

    if (error) {
      console.error('Error deleting league division:', error);
      throw new Error('Error deleting league division');
    }

    // The league_divisions entry should cascade delete the division mapping

    return true;
  }

  async deleteEventDivision(event_id: number, division_id: number): Promise<boolean> {
    const { error } = await this.supabase.from(EVENT_DIVISIONS_TABLE).delete().match({ event_id, division_id });

    if (error) {
      console.error('Error deleting event division:', error);
      throw new Error('Error deleting event division');
    }

    // We don't delete from DIVISIONS_TABLE as it might be used in other events

    return true;
  }

  async getLeague(leagueId: string) {
    const data = await this.supabase.from(LEAGUES_TABLE).select('*, league_admins(*)').match({ id: leagueId }).returns<LeagueWithAdmins[]>().limit(1).single();
    return data.data;
  }

  // listSubscriptions() {
  //   console.log('subs: ', this.supabase.getSubscriptions());
  // }

  async getBout(boutId: string) {
    const { data, error } = await this.supabase
      .from(MATCHES_TABLE)
      .select(
        '*, ' +
          'event:league_event_id(event_start_date, name),' +
          'wrestler1(id, first_name, last_name, team_id, team:team_id(name), wrestler_id),' +
          'wrestler2(id, first_name, last_name, team_id, team:team_id(name), wrestler_id)',
      )
      .match({ id: boutId })
      .returns<MatchWithEventAndWrestlers[]>()
      .limit(1)
      .single();
    if (error) {
      this.showToast('Unable to load match');
    }

    return data;
  }

  async getBoutByUuid(uuid: string) {
    const { data, error } = await this.supabase
      .from(MATCHES_TABLE)
      .select(
        '*, ' +
          'wrestler1(id, first_name, last_name, team_id, team:team_id(name), wrestler_id),' +
          'wrestler2(id, first_name, last_name, team_id, team:team_id(name), wrestler_id),' +
          'match_settings:match_settings_id(*),' +
          'bracket:bracket_id(*)',
      )
      .match({ uuid })
      .returns<MatchWithWrestlersAndSettingsAndBracket[]>()
      .limit(1)
      .single();
    if (error) {
      this.showToast('Unable to load match');
    }
    return data;
  }

  async getBoutForEvent(league_event_id: string, match_number: string) {
    await this.spinnerShow();
    const { data, error } = await this.supabase
      .from(MATCHES_TABLE)
      .select(
        '*, ' +
          'wrestler1(id, first_name, last_name, team_id, team:team_id(name), wrestler_id),' +
          'wrestler2(id, first_name, last_name, team_id, team:team_id(name), wrestler_id),' +
          'match_settings:match_settings_id(*),' +
          'bracket:bracket_id(*)',
      )
      .match({ league_event_id, match_number })
      .limit(1)
      .returns<MatchWithWrestlersAndSettingsAndBracket[]>()
      .single();
    if (error) {
      this.showToast('Unable to load match');
    }

    // console.log('BOUT FOR EVENT', data);
    await this.spinnerHide();
    return data;
  }

  async clearBoutEvents(match_id: number) {
    await this.spinnerShow();

    const { data, error } = await this.supabase.from(BOUT_EVENTS_TABLE).delete().match({ match_id });

    await this.spinnerHide();

    if (error) {
      this.showToast('Unable to clear bout events');
    }
  }

  async getBoutEvents(match_id: number) {
    await this.spinnerShow();
    const { data, error } = await this.supabase.from(BOUT_EVENTS_TABLE).select('*').match({ match_id }).order('created_at').returns<MatchActivity[]>();
    await this.spinnerHide();
    // console.log('BOUT EVENTS FROM SUPABASE', data);

    return data || [];
  }

  async createAndUpdateTeamWrestlers(teamId: number, wrestlers: WrestlerImport[]) {
    await this.spinnerShow();
    // if this fails, we should log an error via toast!
    const w = await this.createWrestlers(teamId, wrestlers);
    await this.spinnerHide();
    this.updateCurrentTeamsWrestlers(teamId);
    return w;
  }

  async updateCurrentTeamsWrestlers(teamId: number) {
    this._currentTeamWrestlers.next(await this.getTeamWrestlers(teamId.toString()));
  }

  async addLeagueAdmin(leagueId: number, email: string) {
    // look up the user by email
    const { data, error } = await this.supabase.from('users').select('id').eq('email', email).limit(1).single();
    if (error) {
      this.showToast('Unable to find user');
      return;
    }
    if (!data) {
      this.showToast('Unable to find user');
      return;
    }
    const userId = data.id;
    // check if user is already an admin
    const { data: data1, error: error1 } = await this.supabase.from('league_admins').select('id').eq('league_id', leagueId).eq('user_uuid', userId);
    console.log('user_uuid, league_id', userId, leagueId);

    if (error1) {
      this.showToast('Unable to query league admin table');
      return;
    }
    if (data1.length > 0) {
      console.log('data1', data1);
      this.showToast('User is already an admin');
      return;
    }

    console.log("we didn't find this user as an admin, adding...");
    // add the user to the league admins
    const { data: data2, error: error2 } = await this.supabase.from('league_admins').insert({ league_id: leagueId, user_uuid: userId });
    if (error2) {
      this.showToast('Unable to add user');
      return;
    }
    this.showToast('User added');
  }
  async createTeamAndUpdateTeamsList(leagueId: string, name: string) {
    const team = await this.createTeam(leagueId, name);

    this.updateCurrentTeamsList(+leagueId);
    return team;
  }

  async createTeam(leagueId: string, name: string) {
    // TODO - this return an array or a single?
    const data = await this.supabase.from(TEAMS_TABLE).insert({ league_id: leagueId, name: name }).select().returns<Team[]>().single();
    return data.data;
  }

  async spinnerShow() {
    await this.spinner.show('blank');
  }
  async spinnerHide() {
    await this.spinner.hide('blank');
  }

  async updateBatchEvents(events: MatchActivity[]) {
    let res = await this.supabase.from('match_activities').upsert(events, { onConflict: 'uuid' });
  }

  async sendBoutEvent(bout_event: MatchActivityUpdate) {
    delete bout_event.id;

    // Check if we're online
    const isOnline = await this.networkService.onlineStatus$.pipe(take(1)).toPromise();

    if (isOnline) {
      try {
        let data = await this.supabase.from(BOUT_EVENTS_TABLE).upsert(bout_event, { onConflict: 'uuid' });

        if (data.error) {
          await this.delay(400);
          data = await this.supabase.from(BOUT_EVENTS_TABLE).upsert(bout_event, { onConflict: 'uuid' });

          if (data.error) {
            // If still failed after retry, store for offline sync
            await this.offlineManager.storePendingBoutEvent(bout_event);
            return { success: false, error: data.error };
          }
        }
        return { success: true, data: data.data };
      } catch (error) {
        console.error('Error sending bout event:', error);
        // Store for offline sync if online attempt fails
        await this.offlineManager.storePendingBoutEvent(bout_event);
        return { success: false, error };
      }
    } else {
      // Store for offline sync
      await this.offlineManager.storePendingBoutEvent(bout_event);
      return { success: true, data: null }; // Return success so UI can continue
    }
  }

  async deleteBoutEvent(bout_event: MatchActivity) {
    // let obj = { ...bout_event };
    console.log('delete event', bout_event);

    const data = await this.supabase.from(BOUT_EVENTS_TABLE).delete().eq('uuid', bout_event.uuid);
    return data.data;
  }

  // calculateComposite(eventWrestler) {
  //   // const weight =
  //   return +(
  //     10 *
  //     (1.1 * eventWrestler['new_weight'] +
  //       15 * this.age(eventWrestler['dob']) +
  //       17 * eventWrestler['rank'])
  //   ).toFixed(0);
  // }

  // @TODO: add ability to link leagues, and ability to add wrestlers from other events.
  // @TODO: 3 match system in auto matchup.
  // @TODO: revisit the disallow repeats function
  // @TODO: dynamic match scheduling

  async setManualEventWrestlers(eventId: number, wrestlers: Wrestler[]) {
    // get rid of possible dups
    // let uniques: Wrestler[] = [];

    // wrestlers.forEach((w) => {
    //   if (uniques.findIndex((u) => u.id == w.id) < 0) {
    //     if (!w.is_weighed_in) {
    //       uniques.push(w);
    //     }
    //   }
    // });
    // let uniques = Array.from(new Set(wrestlers));

    // get current list, and only delete those who are not in the new list
    const { data: existing_wrestlers, error: e2 } = await this.supabase.from(EVENT_WRESTLERS_TABLE).select('wrestler_id').eq('event_id', eventId).eq('manual_added', true);
    console.log('data', existing_wrestlers);
    console.log('error', e2);

    if (!e2) {
      // from existing_wrestlers, filter out those who are in uniques
      const toDelete = existing_wrestlers.filter((w) => {
        return wrestlers.findIndex((u) => u.id == w.wrestler_id) < 0;
      });
      console.log('to delete', toDelete);

      // now, get the list of ids to insert (they are in uniques, but not in existing_wrestlers)
      const toInsert = wrestlers.filter((w) => {
        return existing_wrestlers.findIndex((u) => u.wrestler_id == w.id) < 0;
      });

      console.log('to insert', toInsert);

      // manually delete the wrestlers who need deleted, but only if manually added and not weighed in
      const { error } = await this.supabase
        .from(EVENT_WRESTLERS_TABLE)
        .delete()
        .in(
          'wrestler_id',
          toDelete.map((w) => w.wrestler_id),
        )
        .eq('event_id', eventId)
        .eq('manual_added', true)
        .eq('is_weighed_in', false);

      if (error) {
        this.showToast('Some wrestlers could not be removed');
      }

      let res = await this.supabase.from(EVENT_WRESTLERS_TABLE).insert(
        toInsert.map((wrestler) => {
          return {
            event_id: eventId,
            wrestler_id: wrestler.id,
            team_id: wrestler.team_id,
            rank: wrestler.rank,
            composite: wrestler.composite,
            wins: wrestler.wins,
            losses: wrestler.losses,
            dob: wrestler.dob,
            weight: wrestler.weight,
            first_name: wrestler.first_name,
            last_name: wrestler.last_name,
            is_weighed_in: false,
            manual_added: true,
            grade: wrestler.grade,
            gender: wrestler.gender,
          };
        }),
      );
    }
  }
  async recordWrestlerWeight(event_id: number, wrestler: Wrestler, currentWeight: number) {
    let eventWrestler = {
      event_id: event_id,
      is_weighed_in: true,
      team_id: wrestler.team_id,
      rank: wrestler.rank,
      // Composite is now handled in database triggers
      // composite: wrestler.composite,
      wins: wrestler.wins,
      losses: wrestler.losses,
      weight: wrestler.weight, // previous weight.
      new_weight: currentWeight,
      dob: wrestler.dob,
      wrestler_id: wrestler.id,
      first_name: wrestler.first_name,
      last_name: wrestler.last_name,
      weighed_at: new Date().toISOString(),
      gender: wrestler.gender,
      grade: wrestler.grade,
    };

    // Now handled in triggers...
    // eventWrestler.composite = this.calculateComposite(eventWrestler);

    // console.log('add weight for ', eventWrestler);
    let res = await this.supabase.from(EVENT_WRESTLERS_TABLE).upsert({ ...eventWrestler }, { onConflict: 'event_id,wrestler_id' });

    console.log('recordWrestlerWeight', res);
    // TODO fix return?
    return res;
  }

  async removeWrestlerWeight(event_id: number, wrestler: Wrestler) {
    // console.log('add weight for ', eventWrestler);

    let res = await this.supabase.from(EVENT_WRESTLERS_TABLE).delete().eq('event_id', event_id).eq('wrestler_id', wrestler.id);

    return res;
  }

  async getHighestMatchNumber(eventId: number, mat: number) {
    // TODO: do we need a better type here?
    let res = await this.supabase.from('matches').select('match_number').eq('league_event_id', eventId).eq('mat', mat).order('match_number', { ascending: false }).limit(1).single();

    console.log('we got highest number? ', res);
    return res.data?.match_number || 1000 + mat * 100;
  }

  getMatchForInsert(
    uuid: string,
    eventId: number,
    bracket_id: number,
    bracket_uuid: string,
    mat: number,
    match: MatchWithWrestlersUpdate,
    round: number,
    process_linked_matches: boolean,
    division_id: number,
    match_settings_id: number,
  ) {
    let matchSubmit = {
      uuid,
      league_event_id: eventId,
      status: match.status ? match.status : 'NOT_STARTED',
      result: match.result ? match.result : '',

      mat: mat ? mat : 0,
      period: 1,
      bracket_id,
      bracket_uuid,
      match_number: match.match_number,
      order: 1.0 * (match.match_number || 0),
      wrestler1: null,
      wrestler2: null,
      w1_from_match: null,
      w1_from_string: '',
      w2_from_match: null,
      w2_from_string: '',
      winner_to_match: null,
      winner_to_pos: 0,
      loser_to_match: null,
      loser_to_pos: 0,
      placement: 0,
      name: match.name ? match.name : '',
      round,
      round_type: match.round_type ? match.round_type : 0,
      winner: match.winner ? match.winner : null,
      loser: match.loser ? match.loser : null,
      division_id,
      match_settings_id,
    } as Match;

    if (match.placement) {
      matchSubmit['placement'] = match.placement;
    }

    if (match.wrestler1) {
      matchSubmit['wrestler1'] = match.wrestler1.id;
    }
    if (match.wrestler2) {
      matchSubmit['wrestler2'] = match.wrestler2.id;
    }

    if (process_linked_matches) {
      if (match.w1_from_match) {
        matchSubmit['w1_from_match'] = match.w1_from_match;
        matchSubmit['w1_from_string'] = match.w1_from_string || '';
      }

      if (match.w2_from_match) {
        matchSubmit['w2_from_match'] = match.w2_from_match;
        matchSubmit['w2_from_string'] = match.w2_from_string || '';
      }

      if (match.winner_to_match) {
        matchSubmit['winner_to_match'] = match.winner_to_match;
        matchSubmit['winner_to_pos'] = match.winner_to_pos || null;
        matchSubmit['loser_to_match'] = match.loser_to_match || null;
        matchSubmit['loser_to_pos'] = match.loser_to_pos || null;
      }
    }
    return matchSubmit;
  }

  async insertMatch(
    uuid: string,
    eventId: number,
    bracket_id: number | null,
    bracket_uuid: string | null,
    mat: number,
    matchNumber: number,
    wrestler1: Wrestler | null,
    wrestler2: Wrestler | null,
    round: number,
    division_id: number,
    match_settings_id: number,
    process_linked_matches: boolean,
  ) {
    let matchSubmit = {
      uuid,
      league_event_id: eventId,
      mat,
      period: 1,
      bracket_id,
      bracket_uuid,
      match_number: matchNumber,
      order: 1.0 * matchNumber,
      wrestler1: null,
      wrestler2: null,
      w1_from_match: null,
      w2_from_match: null,
      w1_from_string: '',
      w2_from_string: '',
      winner_to_match: null,
      winner_to_pos: 0,
      loser_to_match: null,
      loser_to_pos: 0,
      division_id,
      match_settings_id,
      // TODO fix these
      // loser to x
      //winner_to_x
      round,
    } as Match;
    if (wrestler1?.id) {
      matchSubmit['wrestler1'] = wrestler1.id;
    }
    if (wrestler2?.id) {
      matchSubmit['wrestler2'] = wrestler2.id;
    }

    // This is a one-off match insertion, no linked match handling.
    // if (process_linked_matches) {
    //   if (match.w1_from_match) {
    //     matchSubmit['w1_from_match'] = match.w1_from_match;
    //     matchSubmit['w1_from_string'] = match.w1_from_string || '';
    //   }

    //   if (match.w2_from_match) {
    //     matchSubmit['w2_from_match'] = match.w2_from_match;
    //     matchSubmit['w2_from_string'] = match.w2_from_string || '';
    //   }

    //   if (match.winner_to_match) {
    //     matchSubmit['winner_to_match'] = match.winner_to_match;
    //     matchSubmit['winner_to_pos'] = match.winner_to_pos;
    //     matchSubmit['loser_to_match'] = match.loser_to_match;
    //     matchSubmit['loser_to_pos'] = match.loser_to_pos;
    //   }
    // }

    // console.log(matchSubmit);
    let res = await this.supabase.from(MATCHES_TABLE).upsert(matchSubmit, { onConflict: 'uuid' });
    return res;
  }

  async updateWrestlerRanks(ranks: WrestlerRankUpdate[]) {
    // console.log('update ranks', ranks);
    // we need wrestler_id -> id and new_rank -> rank
    const toUpdate = ranks.map((r) => {
      return { id: r.wrestler_id, rank: r.new_rank };
    });

    // console.log('to update', toUpdate);
    let res = await this.supabase.from(WRESTLERS_TABLE).upsert(toUpdate);
    // handle errors
    if (res.error) {
      console.error('Error updating wrestler ranks:', res.error);
      return false;
    }
    return res.count;
  }

  async insertMatchesBulk(matches: Match[]) {
    let res = await this.supabase.from(MATCHES_TABLE).upsert(matches, { onConflict: 'uuid' });
    return res;
  }

  async getTeamWinsLossesByLeague(leagueId: number) {
    let res = await this.supabase.from('team_wins_losses').select('*').eq('league_id', leagueId).order('percent', { ascending: false }).returns<TeamWinsLossesView[]>();
    console.log(res);
    return res.data || [];
  }
  async getTeamWinsLossesByTeam(teamId: number) {
    let res = await this.supabase.from('team_wins_losses').select('*').eq('id', teamId).returns<TeamWinsLossesView[]>();
    // console.log(res);
    return res.data || [];
  }

  async getTeamEventsForAgainstByLeague(leagueId: number) {
    // get league team ids
    const t = await this.getLeagueTeams(leagueId.toString());
    if (t && t.length) {
      let res = await this.supabase
        .from('events_for_against_per_team')
        .select('*')
        .in(
          'team_against',
          t.map((team) => team.id.toString()),
        )
        .returns<EventsForAgainstPerTeamView[]>();
      console.log(res);
      return res.data || [];
    }
    return [];
  }

  async getTeamWinsLossesByEvent(eventId: number) {
    let res = await this.supabase.from('team_wins_losses_by_event').select('*').eq('league_event_id', eventId);
    // console.log(res);
    return res.data || [];
  }

  saveBoutClockToLocalStorage(clock: string) {
    localStorage.setItem('BOUT-CLOCK', clock);
  }

  saveBoutToLocalStorage(bout: MatchWithWrestlers | null) {
    let toSubmit;
    if (bout) {
      // strip down how much we are putting into storage
      toSubmit = JSON.parse(JSON.stringify(bout));

      delete toSubmit['created_at'];
      delete toSubmit['league_event_id'];
      delete toSubmit['started_at'];
      delete toSubmit['finished_at'];
      delete toSubmit['period_length'];
      delete toSubmit['winner_to_match'];
      delete toSubmit['loser_to_match'];
      delete toSubmit['bracket_id'];
      delete toSubmit['order'];
      delete toSubmit['winner_to_pos'];
      delete toSubmit['loser_to_pos'];
      delete toSubmit['winner_from_match'];
      delete toSubmit['loser_from_match'];
      delete toSubmit['round'];
      delete toSubmit['bracket_uuid'];
      delete toSubmit['uuid'];
      delete toSubmit['bracket'];
      delete toSubmit['activity_points'];
      delete toSubmit['advancement_points'];
      delete toSubmit['division_id'];
      delete toSubmit['match_settings_id'];
      delete toSubmit['match_settings'];
      delete toSubmit['placement'];
      delete toSubmit['round_type'];
      delete toSubmit['w1_from_match'];
      delete toSubmit['w1_from_string'];
      delete toSubmit['w2_from_match'];
      delete toSubmit['w2_from_string'];
      delete toSubmit['elo_diff'];
      delete toSubmit['blood1'];
      delete toSubmit['blood2'];
      delete toSubmit['fall_time'];
      delete toSubmit['injury1'];
      delete toSubmit['injury2'];
      delete toSubmit['recovery1'];
      delete toSubmit['recovery2'];
      // console.log('bout to submit');
      // console.log(toSubmit);
    } else {
      toSubmit = null;
    }

    // TODO consider making clock it's own storage
    localStorage.setItem('BOUT', JSON.stringify(toSubmit));
  }

  async invokeFunction(name: string, body = null) {
    let options = {};
    //{ body: JSON.stringify({ foo: 'bar' }

    return await this.supabase.functions.invoke(name, options);
  }

  async deleteBout(boutId: number) {
    const { error } = await this.supabase.from(MATCHES_TABLE).delete().eq('id', boutId);
    // console.log('delete bout', error);
    if (error) {
      this.showToast('Unable to delete match', {
        duration: 4000,
        position: 'middle',
      });
    } else {
      this.showToast('Match deleted', {
        duration: 4000,
        position: 'middle',
      });
    }
    // return true if deleted, false if error
    return !error;
  }

  // flag errors here
  async updateBout(bout: MatchWithWrestlersAndSettingsAndBracket, spinner = false, includeWrestler = false, throwError = false) {
    // Store locally to allow UI continuity and offline score viewing
    this.saveBoutToLocalStorage(bout);

    // Check online status (still may have limited internet)
    const isOnline = await this.networkService.onlineStatus$.pipe(take(1)).toPromise();

    if (!isOnline) {
      await this.offlineManager.storePendingMatchUpdate(bout);
      return { success: true };
    }

    const id = bout.id;
    const { match_settings, bracket, wrestler1, wrestler2, ...restBout } = bout;
    let toSubmit: MatchUpdate = { ...restBout };

    delete toSubmit['id'];
    if (includeWrestler) {
      toSubmit['wrestler1'] = wrestler1?.id;
      toSubmit['wrestler2'] = wrestler2?.id;
    }

    if (spinner) await this.spinnerShow();

    // Attempt update with retry mechanism
    const maxRetries = 2;
    let attempt = 0;
    let success = false;
    let data = null;

    while (attempt < maxRetries && !success) {
      attempt++;
      data = await this.supabase.from(MATCHES_TABLE).update(toSubmit).match({ id });

      if (!data.error) {
        success = true;
      } else {
        console.warn(`Attempt ${attempt} failed: ${data.error.message}`);
        await this.delay(400 * attempt); // Exponential backoff delay
      }
    }

    // Handle persistent failure
    if (!success) {
      await this.offlineManager.storePendingMatchUpdate(bout);

      if (throwError) {
        this.showToast('Unable to save match, please try again', {
          duration: 4000,
          position: 'middle',
        });
        throw new Error(data?.error?.message ?? 'Unknown error');
      }
    }

    if (spinner) await this.spinnerHide();

    return { success, data: success ? data?.data : null };
  }

  async delay(milliseconds: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }
  // getBoutEventsLive(boutId, callback) {
  //   // return this.supabase
  //   //   .channel('bout_events')
  //   //   .on(
  //   //     'postgres_changes',
  //   //     {
  //   //       event: 'INSERT',
  //   //       schema: 'public',
  //   //       table: 'bout_events',
  //   //     },
  //   //     (payload) => {
  //   //       callback(payload);
  //   //     }
  //   //   )
  //   //   .subscribe();
  //   // v1
  //   return this.supabase
  //     .from(BOUT_EVENTS_TABLE + ':match_id=eq.' + boutId)
  //     .on('*', callback)
  //     .subscribe();
  // }

  // getBoutLive(boutId, callback) {
  //   return this.supabase
  //     .from(MATCHES_TABLE + ':id=eq.' + boutId)
  //     .on('*', callback)
  //     .subscribe();
  // }

  async clearLastLeague() {
    this._currentLeague.next(null);
    this._currentTeamsList.next([]);
  }
  async updateEventAutogenSettings(event_id: number, division_id: number) {
    this._currentAutogenSettings.next(await this.getEventAutogenSettings(event_id, division_id));
  }
  async updateLeagueWeightClasses(leagueId: number) {
    console.log('DATASERVICE: updating weightclasses for league');
    this._currentWeightClasses.next(await this.getLeagueWeightClasses(leagueId.toString()));
  }
  async updateLeagueEventsList(leagueId: number) {
    console.log('DATASERVICE: updating events for league');
    this._currentLeagueEvents.next(await this.getLeagueEvents(leagueId.toString()));
  }

  async putTeamEvent(event_id: number, name: string, event_start_date: string, details: string) {
    console.log('DATASERVICE: update the event', event_id, name, details);

    let { data, error } = await this.supabase
      .from(LEAGUE_EVENTS_TABLE)
      .update({
        name,
        event_start_date,
        details,
        // mats,
      }) // add mats
      .match({ id: event_id })
      .select()
      .returns<LeagueEvent>()
      .single();
    this._currentEvent.next(data);
  }

  // TODO: FINISH COPYING ALL SETTINGS (weight classes, mats, etc)
  async createLeagueEvent(league_id: number, name: string, event_date: string, teams: Team[]) {
    let { data, error } = await this.supabase.rpc('create_event_and_copy_settings', {
      p_league_id: league_id,
      p_event_name: name,
      p_event_date: event_date,
      p_team_ids: teams.map((t) => t.id),
    });
    console.log(data);

    // handle errors
    if (error) {
      console.error('Error creating event:', error);
      // show alert
      this.showToast('Failed to create event', { duration: 3000 });
      return;
    }

    this.updateLeagueEventsList(league_id);
  }

  async getWrestlerMatchScheduleByTeam(event_id: number) {
    const { data, error } = await this.supabase.rpc('get_event_wrestler_matches', { event_id_param: event_id }).select('*');

    if (error) {
      console.error('Error fetching data:', error);
      return;
    }
    let wrestlersByTeam = (data as WrestlerEventMatches).reduce(
      (acc, wrestler) => {
        if (!acc[wrestler.team_name]) {
          acc[wrestler.team_name] = [];
        }
        acc[wrestler.team_name].push(wrestler);
        return acc;
      },
      {} as Record<string, WrestlerEventMatches>,
    );

    console.log('wrestlersByTeam', wrestlersByTeam);
    return wrestlersByTeam || [];
  }

  async updateAllTeamsInEventList(eventId: number) {
    // console.log('attempting to update teams list for league ', leagueId);
    const t = await this.getAllTeamsInEvent(eventId.toString());
    this._currentAllTeamsInEventList.next(t);
    // console.log('emitted teams: ', t);
  }
  async updateCurrentTeamsList(leagueId: number) {
    console.log('attempting to update teams list for league ', leagueId);
    const t = await this.getLeagueTeams(leagueId.toString());
    this._currentTeamsList.next(t);
    // console.log('emitted teams: ', t);
  }
  async setCurrentLeague(leagueId: number) {
    this.currentLeagueId = leagueId;
    this.clearLastLeague();

    console.log('DATASERVICE: setCurrentLeague');

    // this._currentLeague.next(await this.getLeague(leagueId.toString()));
    this._currentLeague.next(await this.getLeague(leagueId.toString()));
    await this.updateCurrentTeamsList(leagueId);
    await this.updateLeagueEventsList(leagueId);
    await this.updateLeagueWeightClasses(leagueId);
  }

  async updateCurrentLeague(leagueId: number) {
    console.log('DATASERVICE: updateCurrentLeague');
    this._currentLeague.next(await this.getLeague(leagueId.toString()));
  }

  async setAllDataFromStorage() {
    // const srData = await this.getData(FULL_STOREROOM_DATA);
    // if (srData) {
    //   for (const key in srData) {
    //     if (srData.hasOwnProperty(key)) {
    //       this[key].next(srData[key]);
    //     } else {
    //       console.error('Cannot find key in data: ', key);
    //     }
    //   }
    // }
  }

  async storeData(nodeName: string, data: any) {
    return await this.storage.set(nodeName, data);
  }
  async getData(nodeName: string) {
    return await this.storage.get(nodeName);
  }
  async clear() {
    this.storage.clear();
  }

  /* from controller..
async selectImage() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt
    });

    const loading = await this.loadingCtrl.create({
      message: 'Uploading image...'
    });
    await loading.present();

    this.databaseService.uploadFile(image, this.listId).then(async res => {
      loading.dismiss();
      if (res.error) {
        const alert = await this.alertController.create({
          header: 'Ooops faild!',
          message: 'Upload failed: ' + res.error.message,
          buttons: ['OK'],
        });
        await alert.present();
      } else {
        // Split the bucket name from the path
        this.databaseService.updateListAvatar(this.listId, res.data.Key.split('/')[1]);
      }
    });
  }*/

  async uploadFile(cameraFile: Photo, id: number): Promise<any> {
    let file = null;
    // Retrieve a file from the URI based on mobile/web
    if (isPlatform('hybrid')) {
      // console.log('read from FS');
      const { data } = await Filesystem.readFile({
        path: cameraFile.path || '',
        // directory: Directory.Data, <- REMOVE THIS!
      });
      // console.log('after read file: ', data);
      file = await this.dataUrlToFile(data as string);
    } else {
      const blob = await fetch(cameraFile.webPath || '').then((r) => r.blob());
      file = new File([blob], 'myfile', {
        type: blob.type,
      });
    }

    const fileName = `wrestler/${id}.png`;

    // Remove any previous image for the list
    // console.log('trying to write', fileName);

    await this.supabase.storage.from(BUCKET).remove([fileName]);

    // Upload the file to Supabase
    await this.supabase.storage.from(BUCKET).upload(fileName, file);

    return this.supabase.storage.from(BUCKET).getPublicUrl(fileName);
  }

  async updateWrestlerPhoto(id: number, fileName: string) {
    // console.log('trying to save filename', fileName);

    return this.supabase.from(WRESTLERS_TABLE).update({ photo: fileName }).match({ id }).select().returns<Wrestler>();
  }

  // Helper
  private dataUrlToFile(dataUrl: string, fileName: string = 'myfile'): Promise<File> {
    return fetch(`data:image/png;base64,${dataUrl}`)
      .then((res) => res.blob())
      .then((blob) => {
        return new File([blob], fileName, { type: 'image/png' });
      });
  }

  // not currently used
  getListAvatar(fileName: string) {
    return this.supabase.storage
      .from(BUCKET)
      .download(fileName)
      .then((res) => {
        if (!res.data) {
          return null;
        }
        const url = URL.createObjectURL(res.data);
        const imageUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        return imageUrl;
      });
  }

  async downloadBlobAsFile(blobData: Blob, filename: string) {
    // Create an anchor element and attach the blob data as an ObjectURL
    const url = window.URL.createObjectURL(blobData);

    // Create a 'hidden' anchor tag with the download attribute and trigger a click event
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;

    // Append the anchor to the DOM and simulate a click to start the download
    document.body.appendChild(a);
    a.click();

    // Cleanup: remove the anchor and revoke the ObjectURL to free up resources
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  async getLeagueWeights(league_id: number) {
    await this.spinnerShow();
    // const r = await this.supabase.functions.invoke('export-league-weights', {
    //   body: JSON.stringify({ league_id }),
    // });
    const r = await this.supabase.functions.invoke('export-league-stats', {
      body: JSON.stringify({ league_id }),
    });

    // Create a Blob object with the data
    const blob = new Blob([r.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    await this.spinnerHide();
    // Call the refactored function
    this.downloadBlobAsFile(blob, 'league_weights.xlsx');
  }

  async getEventResults(event_id: number) {
    await this.spinnerShow();
    const r = await this.supabase.functions.invoke('export-event-results', {
      body: JSON.stringify({ event_id }),
    });

    // Create a Blob object with the data
    const blob = new Blob([r.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    await this.spinnerHide();
    // Call the refactored function
    this.downloadBlobAsFile(blob, 'event_results.xlsx');
  }

  downloadTeamTemplate() {
    const link = document.createElement('a');
    link.href = 'assets/wrestler_import_template.xlsx'; // Set your file path here
    link.download = 'wrestler_import_template.xlsx'; // This sets the filename for the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async createNewLeague(name: string, admin_uuid: string): Promise<number | null> {
    try {
      const { data, error } = await this.supabase.rpc('create_new_league', {
        p_name: name,
        p_admin_id: admin_uuid,
      });

      if (error) {
        console.error('Error creating new league:', error);
        this.showToast('Failed to create new league', { duration: 3000 });
        return null;
      }

      if (data) {
        this.showToast('New league created successfully', { duration: 3000 });
        return data as number;
      }

      return null;
    } catch (error) {
      console.error('Unexpected error creating new league:', error);
      this.showToast('An unexpected error occurred', { duration: 3000 });
      return null;
    }
  }

  async saveDivisionPositions(divisions: (DivisionUpdate | EventDivisionUpdate | LeagueDivisionUpdate)[]) {
    // Implement the logic to save the updated division positions to the database
    // You may need to separate the divisions based on their type (Division, EventDivision, or LeagueDivision)
    // and call the appropriate methods to update their positions
  }

  async searchUsers(searchTerm: string): Promise<SearchUser[]> {
    try {
      const { data, error } = await this.supabase.from('users').select('id, email').ilike('email', `%${searchTerm}%`).limit(10);

      if (error) {
        console.error('Error searching users:', error);
        this.showToast('Error searching users', { duration: 3000 });
        return [];
      }

      return data || [];
    } catch (error) {
      console.error('Unexpected error searching users:', error);
      this.showToast('An unexpected error occurred', { duration: 3000 });
      return [];
    }
  }

  // Process pending sync when back online
  async processPendingSync() {
    if (this.syncInProgress) return;

    this.syncInProgress = true;

    // First sync match updates
    const pendingUpdates = await this.offlineManager.getPendingMatchUpdates();
    for (const update of pendingUpdates) {
      try {
        await this.updateBout(update.match);
        await this.offlineManager.removePendingMatchUpdate(update.match.id);
      } catch (error) {
        // Handle error, maybe retry
      }
    }

    const pendingEvents = await this.offlineManager.getPendingEvents();

    console.log('pending events', pendingEvents);
    if (pendingEvents.length === 0) {
      this.syncInProgress = false;
      return;
    }

    const failedEvents = [];

    for (const pendingEvent of pendingEvents) {
      try {
        const result = await this.sendBoutEvent(pendingEvent.event);
        if (!result.success) {
          pendingEvent.retryCount++;
          if (pendingEvent.retryCount < 3) {
            failedEvents.push(pendingEvent);
          }
        }
      } catch (error) {
        pendingEvent.retryCount++;
        if (pendingEvent.retryCount < 3) {
          failedEvents.push(pendingEvent);
        }
      }
    }

    // Save any failed events back to storage
    await this.offlineManager.updatePendingEvents(failedEvents);
    this.syncInProgress = false;
  }
}
